import { rankTypes } from "./rankTypes"


const initialState = {
    ranks: [],
    modalOpenG: false,
    activeRank: null
}

export const rankReducer = (state = initialState, action) => {
    switch (action.type) {
        case rankTypes.rankSetActive:
            return{
                ...state,
                activeRank: action.payload,
            }
        case rankTypes.rankAddNew:
            return{
                ...state,
                ranks:[
                    ...state.ranks,
                    action.payload
                ] 
            }
        case rankTypes.rankClearActiveRank:
            return{
                ...state,
                activeRank: null
            }
        case rankTypes.rankUpdated:
            return{
                ...state,
                ranks: state.ranks.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case rankTypes.rankDeleted:
            return{
                ...state,
                ranks: state.ranks.filter(
                    b => (b.id !== state.activeRank.id)
                ),
                activeRank: null
            }
        case rankTypes.rankLoaded:
            return{
                ...state,
                ranks: action.payload,
            }
        case rankTypes.OpenMRank:
            return {
                ...state,
                modalOpenG: true
            }
        case rankTypes.CloseMRank:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
