import { backTypes } from "./backTypes";


const initialState = {
    history: '/'
}

export const backReducer = (state = initialState, action) => {
    switch (action.type) {   
        case backTypes.history:
            return {
                ...state,
                history: action.payload
            }
        default:
            return state;
    }
}