import React, { useState, useEffect } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { MainButton } from '../buttons/main/MainButton';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { activitiesStartUpdate, activitieStartAdd, activityClearActive, CloseMActivity } from './activitiesActions';
const customStyles = {
    content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: '0%',
        Transform: 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initial = {
    name: '',
    evidence: '',
    start: Date(),
    end: Date(),
    activo: 1,
}

export const ActivityModal = () => {
    const { modalOpen, activeActivity } = useSelector(state => state.activities);
    const [formValues, setFormValues] = useState(initial);
    const [titleValid, settitleValid] = useState(true);
    const dispatch = useDispatch();
    const { name, evidence, start, end, activo } = formValues;
    //const activeActivity = null;
    useEffect(() => {

        if (activeActivity) {
            setFormValues(activeActivity);
        } else {
            setFormValues(initial);
        }
    }, [activeActivity, setFormValues]);
    const closeModal = () => {
        dispatch(CloseMActivity());
        dispatch(activityClearActive());
        setFormValues(initial);
    }
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) => {
        e.preventDefault();

        if (name.trim().length < 1) {
            settitleValid(false);
            return;
        }
        if (activeActivity) {
            dispatch(activitiesStartUpdate(formValues));
        } else {
            dispatch(activitieStartAdd(formValues));
        }
        settitleValid(true);
        closeModal();
    }
    return (
        <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            closeTimeoutMS={200}
            style={customStyles}
            className='modal'
            overlayClassName='modal-fondo'
        >
            <Subtitle value={(activeActivity) ? 'Editar Actividad' : 'Crear Actividad'} close={true} fclose={closeModal} />
            <form
                className="container"
                onSubmit={handleSubmitForm}
            >
                <div className="form-group">
                    <label>Actividad</label>
                    <textarea
                        rows="3"
                        className={`form-control ${!titleValid && 'is-invalid'}`}
                        placeholder="Actividad"
                        name="name"
                        autoComplete="off"
                        value={name}
                        onChange={handleInputChange}
                    />
                    <small id="titleHelp" className="form-text text-muted bg-light">Corresponde a la forma en que vas a cumplir la meta.</small>
                </div>
                <div className="form-group">
                    <label>Evidencia</label>
                    <textarea
                        rows="3"
                        className={`form-control`}
                        placeholder="Al definir los compromisos se fijaran las evidencias que se requieran para verificar el cumplimiento de lo acordado, estas son el producto del desempeño del funcionario"
                        name="evidence"
                        autoComplete="off"
                        value={evidence}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label>Fecha de Inicio</label>
                    <input className='form-control' type={'date'} name='start' value={start} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Fecha Fin</label>
                    <input className='form-control' type={'date'} name='end' value={end} onChange={handleInputChange} />
                </div>
                <div className="form-group">
                    <label>Activo</label>
                    <select
                        className="form-select"
                        aria-label="Activo"
                        name="activo"
                        value={activo}
                        onChange={handleInputChange}
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                    </select>
                    <small id="logoHelp" className="form-text text-muted">Esta activo?</small>
                </div>
                <br />
                <MainButton
                    nameButton="sTypeobjective"
                    idButton="sTypeobjective"
                    valueButton=" Guardar"
                    handleClick={handleSubmitForm}
                    icon="far fa-save"
                />
            </form>
        </Modal>
    );
};
