import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { configuration } from '../../types/conf';
import { BackButton } from '../buttons/back/BackButton';
import { HelpButton } from '../buttons/help/HelpButton';
import { MainButton } from '../buttons/main/MainButton';
import { generalSteps } from '../general/generalActions';
import { NavBar } from '../navbar/NavBar';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { startAddTraining, trainingclose } from './trainingActions';

const initial = {
    name: ''
}

export const TrainingScreen = () => {
    const {add} = useSelector(state=> state.training);
    const [formValues, setFormValues] = useState(initial);
    const [titleValid, settitleValid] = useState(true);
    const { collaborator } = useSelector(state => state.general);
    const collab = collaborator[0];
    const dispatch = useDispatch();
    const {name} = formValues;
    const router = useHistory();
    let updState = {
        step: 3,
        state: 1,
        collaborator: collab.id
      };
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) =>{
        e.preventDefault();
        if(name.trim().length < 1 ){
            settitleValid(false);
            return;
        }
        dispatch(startAddTraining(formValues));
    }
    if(add === true){
        dispatch(generalSteps(updState));
        dispatch(trainingclose());
        router.push('/main');
    }
    return (<>
        <NavBar />
        <div className="container">
            <Subtitle value='Capacitaci&oacute;n' />
            <object data="https://s3.amazonaws.com/rtvc-assets-intranet.rtvc.gov.co/field/fileDocument/Cronograma%20Capacitaci%C3%B3n%202022%20%281%29.pdf" type="application/pdf" style={{ width: "100%", height: "520px" }} >
                <p>Clic para ver el plan de Capacitaci&oacute;n <a target={'_blank'} rel="noreferrer" href="https://s3.amazonaws.com/rtvc-assets-qa-sistemasenalcolombia.gov.co/archivos/plan_estrategico_talento_humano_2022_h.pdf"> Ver PDF!</a></p>
            </object>
            <form className='bg-light'>
                <div className="form-group">
                    <label>Cu&aacute;l es tu plan de capacitaci&oacute;n?</label>
                    <textarea
                        rows="3"
                        className={`form-control ${!titleValid && 'is-invalid'}`}
                        placeholder="Temas seleccionados"
                        name="name"
                        autoComplete="off"
                        value={name}
                        onChange={handleInputChange}
                    />
                    <small id="titleHelp" className="form-text text-muted bg-light">Selecciona del PDF la capacitaci&oacute;n que vas a hacer esta vigencia.</small>
                </div>
            </form>
                <MainButton
                    nameButton="sTraining"
                    idButton="sTraining"
                    valueButton="   Pasar a Concertación de Compromisos"
                    handleClick={handleSubmitForm}
                    icon="far fa-paper-plane"
                    back={configuration.greenColor}
                />
            <HelpButton type='pdf' path='https://s3.amazonaws.com/rtvc-assets-intranet.rtvc.gov.co/field/fileDocument/Calendario%20Planeaci%C3%B3n%202022.pdf'/>
            
            <BackButton path={'/main'} />
        </div>
    </>);
};
