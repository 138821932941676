import React from 'react'
import { useDispatch } from 'react-redux';
import './help.css';
import { OpenMHelp } from './helpActions';
import { HelpModal } from './HelpModal';

export const HelpButton = ({type, path}) => {
  const dispatch = useDispatch();
    const handleClickHelp = () => {
        dispatch(OpenMHelp());
    }
  return (
      <div>
        <button
            className="btn btn-warning fabHelp"
            onClick={handleClickHelp}
        >
            <i className="fas fa-question"></i>
        </button>
        <HelpModal type={type} path={path} />
        </div>
  )
}
