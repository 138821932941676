import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles.css';
import { configuration } from '../../types/conf';
import { MainButton } from '../buttons/main/MainButton';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { CloseMArea, areaClearActiveArea, areaStartAddNew, areaStartUpdate } from './areaActions';
const customStyles={
    content: {
        top        : 'auto',
        left       : 'auto',
        right      : 'auto',
        bottom     : 'auto',
        marginRight: '0%',
        Transform  : 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initArea = {
    name: '',
    year:configuration.year,
    active: 1,
}
export const AreaModal = () => {
    const { activeArea, modalOpenG }  = useSelector(state => state.area);
    const [formValues, setFormValues] = useState(initArea);
    const [titleValid, settitleValid] = useState(true);
    const dispatch = useDispatch();
    const {name, active, year } = formValues;
    useEffect(() => {
        if(activeArea) {
            setFormValues(activeArea);
        }else {
            setFormValues(initArea);
        }
    }, [activeArea, setFormValues]);
    const closeModalG = () => {
        dispatch( CloseMArea());
        dispatch(areaClearActiveArea());
        setFormValues(initArea);
    }
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) =>{
        e.preventDefault();
       
        if(name.trim().length < 1 ){
            settitleValid(false);
            return;
        }
        if( activeArea) {
            dispatch(areaStartUpdate(formValues));
        } else {
            dispatch(areaStartAddNew(formValues));
        }
        settitleValid(true);
        closeModalG();
    }
    return (
        <Modal
            isOpen={modalOpenG}
            onRequestClose={closeModalG}
            style={customStyles}
            closeTimeoutMS={200}
            className='modal'
            overlayClassName='modal-fondo'
        >
            <Subtitle value={ (activeArea) ? 'Editar Area' : 'Crear Area'} close={true} fclose={closeModalG}/>
            <form 
            className="container"
            onSubmit={handleSubmitForm}
            >
            <div className="form-group">
                <label>Nombre</label>
                <input 
                    type="text" 
                    className={`form-control ${!titleValid && 'is-invalid'}`}
                    placeholder="Nombre del area"
                    name="name"
                    autoComplete="off"
                    value={name}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted">Nombre del Area</small>
            </div>

            <div className="form-group">
                <label>Activo</label>
                <select 
                    className="form-select" 
                    aria-label="Active"
                    name="active"
                    value={active}
                    onChange={handleInputChange}
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Esta activo?</small>
            </div>
            <div className="form-group">
                <label>Año</label>
                <select 
                    className="form-select" 
                    aria-label="Year"
                    name="year"
                    value={year}
                    onChange={handleInputChange}
                    >
                        <option value={configuration.year}>{configuration.year}</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Año del area</small>
            </div>
            <br />
            <MainButton
                nameButton="sArea"
                idButton="sArea"
                valueButton=" Guardar"
                handleClick={handleSubmitForm}
                icon="far fa-save"
            />

            </form>
        </Modal>
    )
}