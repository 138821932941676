import React from 'react'
import { helpTypes } from './helpTypes';

const initHelp = {
    openMHelp: false
}

export const helpReducer = (state = initHelp, action) => {
    switch (action.type) {
        case helpTypes.openMHelp:
            return {
                ...state,
                openMHelp: true,
            }
        case helpTypes.closeMHelp:
            return {
                ...state,
                openMHelp: false,
            }
        default:
            return state;
    }
}
