import { gradeTypes } from "./gradeTypes"


const initialState = {
    grades: [],
    modalOpenG: false,
    activeGrade: null
}

export const gradeReducer = (state = initialState, action) => {
    switch (action.type) {
        case gradeTypes.gradeSetActive:
            return{
                ...state,
                activeGrade: action.payload,
            }
        case gradeTypes.gradeAddNew:
            return{
                ...state,
                grades:[
                    ...state.grades,
                    action.payload
                ] 
            }
        case gradeTypes.gradeClearActiveGrade:
            return{
                ...state,
                activeGrade: null
            }
        case gradeTypes.gradeUpdated:
            return{
                ...state,
                grades: state.grades.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case gradeTypes.gradeDeleted:
            return{
                ...state,
                grades: state.grades.filter(
                    b => (b.id !== state.activeGrade.id)
                ),
                activeGrade: null
            }
        case gradeTypes.gradeLoaded:
            return{
                ...state,
                grades: action.payload,
            }
        case gradeTypes.OpenMGrade:
            return {
                ...state,
                modalOpenG: true
            }
        case gradeTypes.CloseMGrade:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
