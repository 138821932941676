import React from "react";
import { configuration } from "../../../types/conf";
import { activitySetActive } from "../../activities/activitiesActions";
import { collaboratorAgreements } from "../../agreements/agreementsActions";
import { areaSetActive } from "../../areas/areaActions";
import { EditButton } from "../../buttons/edit/EditButton";
import { InfoButton } from "../../buttons/info/InfoButton";
import { VariosButton } from "../../buttons/varios/VariosButton";
import { commonSetActive } from "../../commons/commonActions";
import { collaboratorUpdated } from "../../evaluators/evaluatorActions";
import { functionalSetActive } from "../../functional/functionalActions";
import { goalSetActive } from "../../goals/goalsActions";
import { gradeSetActive } from "../../grades/gradeActions";
import { levelSetActive } from "../../levels/levelActions";
import { objectiveSetActive } from "../../objectives/objectiveActions";
import { positionSetActive } from "../../positions/positionActions";
import { rankSetActive } from "../../rank/rankActions";
import { teamSetActive } from "../../teams/teamActions";
import { setUserActive } from "../../users/userActions";

let setActive= ()=>{};
export const Rows = ({ name, index, key, active, edit, update, idList, openModal, info, infoPath, colorVarios, varios, action, modal=true, setVarios}) => {
  let styles = {};
  styles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    opacity: ".95"
  };
  if(idList === 'gradeList'){
    setActive = gradeSetActive(update);
  }
  if(idList === 'levelList'){
    setActive = levelSetActive(update);
  }
  if(idList === 'areaList'){
    setActive = areaSetActive(update);
  }
  if(idList === 'positionList'){
    setActive = positionSetActive(update);
  }
  if(idList === 'rankList'){
    setActive = rankSetActive(update);
  }
  if(idList === 'commonList'){
    setActive = commonSetActive(update);
  }
  if(idList === 'functionalList'){
    setActive = functionalSetActive(update);
  }
  if(idList === 'teamList'){
    setActive = teamSetActive(update);
  }
  if(idList === 'competencies'){
    setActive = positionSetActive(edit);
  }
  if(idList === 'objectiveList'){
    setActive = objectiveSetActive(update);
  }
  if(idList === 'commitList'){
    setActive = goalSetActive(update);
  }
  if(idList === 'activitiesList'){
    setActive = activitySetActive(update);
  }
  if(idList === 'colaboratorList'){
    action = collaboratorUpdated(update);
  }
  if(idList === 'colabEvalList'){
    action = collaboratorUpdated(update);
  }
  if(idList === 'agreeList'){
    setActive = collaboratorAgreements(update);
  }
  if(setVarios !== 'none'){
    if(setVarios === 'users'){
      setUserActive(update);
    }
  }
  if (active === "0") {
    styles.backgroundColor = configuration.inactiveColor;
  } else if (index % 2 === 0) {
    styles.backgroundColor = "#ffffff";
  } else {
    styles.backgroundColor = configuration.rowColor;
  }
  return (
    <div className="w-100 p-2" style={styles}>
      {name}
      <div>
      {
        edit && idList !== 'competencies' ? <EditButton nameButton="edit" idButton={`edit`+index} update={update} setActive={setActive} openModal={openModal} modal={modal}/> : <></>
      }
      <> </>
      {
        info ? <InfoButton path={infoPath} setActive={setActive}/> : <></>
      }
      <> </>
      {
        varios ? <VariosButton nameButton={edit} idButton={`edit`+index} setVarios={setVarios} action={action} varios={varios} colorVarios={colorVarios} /> : <></>
      }
      </div>
    </div>
  );
};
/*
    {
        varios ? <button id={index} className={colorVarios} onClick={action} style={{ paddingBottom: "8px" }}><i className={varios
      }></i></button> : <></>
      */