import React from 'react';
import { NavBar } from '../navbar/NavBar';
import { useDispatch, useSelector } from 'react-redux';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { activitieStartLoading, OpenMActivity } from './activitiesActions';
import { Lists } from '../ui/lists/Lists';
import { BackButton } from '../buttons/back/BackButton';
import { AddButton } from '../buttons/add/AddButton';
import { ActivityModal } from './ActivityModal';
import { useEffect } from 'react';
import { Loading } from '../ui/loading/Loading';

export const ActivitiesScreen = () => {
    const {activeGoal} = useSelector(state => state.goals);
    const {activities, loadingActivities} = useSelector(state => state.activities);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(activitieStartLoading());
    }, [dispatch]);
    if (loadingActivities) {
        return <Loading />;
      }
    return (<div>
        <NavBar />
        <div className='container'>
            <Subtitle value={'Actividades'}/>
            {
            (activities != null)
            ? <Lists rows={activities} edit={true} idList='activitiesList' openModal={OpenMActivity()} />
            : <></>
            }
        </div>
      <BackButton path="/commitments" />
      <AddButton open={OpenMActivity()} />
        <ActivityModal/>
    </div>);
};