import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { configuration } from '../../types/conf';
import { BackButton } from '../buttons/back/BackButton';
import { MainButton } from '../buttons/main/MainButton';
import { collaboratorClear } from '../evaluators/evaluatorActions';
import { generalSteps } from '../general/generalActions';
import { previewStartLoading } from '../preview/previewActions';
import { Review } from '../review/Review';
import { Loading } from '../ui/loading/Loading';
import { AgreementModal } from './AgreementModal';
import { openMagreements } from './agreementsActions';

export const Agreement = () => {
  const { commitments } = useSelector(state => state.preview);
  const { collaborator } = useSelector(state => state.evaluator);
  const router = useHistory();
  let percent = 0;
  let updState = {
    step: 4,
    state: 1,
    collaborator: collaborator.id
  };
  const handleAgree = (e) => {
    e.preventDefault();
    dispatch(generalSteps(updState, true));
    router.push('/main');
  }
  
  const handleDissagree = (e) => {
    e.preventDefault();
    dispatch(openMagreements());
  //  router.push('/main');
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(previewStartLoading(collaborator.id));
  }, [dispatch]);
  if(commitments !== []){
    var idGoal = 0;
    var printGoal = true;
    const totalc = commitments.length - 1 ;
    commitments.map((c, index) => {
      printGoal = (idGoal === c.idGoal) ? false : true;
      idGoal = c.idGoal;
      percent += (printGoal) ? parseInt(c.percent) : 0;
      percent += (totalc === index) ? parseInt(c.percent) : 0;
    });
  }
  if (commitments === []) {
    return <Loading />
  }
  return (
    <div>
      <div style={{ height: '72vh', overflowY: 'scroll', overflowX: 'hidden' }} className="overflow-scroll">
        {
          (commitments !== [])
            ? <Review commitments={commitments} />
            : <></>
        }

      </div>
      {
        (percent === 100)
        ? <div className='row'>
        <div className='col'>
          <MainButton
            nameButton="sGeneral"
            idButton="sGeneral"
            handleClick={handleAgree}
            valueButton="  Aceptar Compromisos"
            icon="far fa-thumbs-up"
            back={configuration.greenColor}
          />
        </div>
        <div className='col'>
          <MainButton
            nameButton="nGeneral"
            idButton="nGeneral"
            handleClick={handleDissagree}
            valueButton=" Rechazar Compromisos"
            icon="far fa-thumbs-down"
            back="red"
            text="white"
          />
        </div>
      </div>
      : <div className="alert alert-danger d-flex align-items-center" role="alert">
      <i className="fas fa-exclamation-circle"></i>
      <div>
         <b> Algo esta mal!</b> El colaborador no ingreso todos sus objetivos, compromisos y actividades o no suman el 100%.
      </div>
    </div>
      }
      <BackButton path="/main" />
      <AgreementModal/>
    </div>
  )
}
