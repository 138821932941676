import Swal from "sweetalert2"
import { fetchConToken } from "../../helpers/fetch"
import { configuration } from "../../types/conf"
import { agreementsType } from "./agreementsTypes"
import { agreePrevStartLoading } from '../preview/previewActions';


export const getCountGoals = () => {
    return async (dispatch, getState) => {
        const resp = fetchConToken(``)
    }
}

export const startAgreementOption = (agree) => {
    return async(dispatch, getState) => {
        console.log(agree);
        const { activeEvaluator } = getState().evaluator;
        agree.evaluator= activeEvaluator[0].id;
        agree.year= configuration.year;
        const resp = await fetchConToken(`agreements/${agree.state}`, agree, 'POST');
        const body = await resp.json();
        if(body.agreement === 'add'){
            dispatch(closeMagreements());
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Datos guardados con exito.',
                showConfirmButton: false,
                timer: 1500
            });
            dispatch(agreeStartLoading());
        }
    }
}

export const collaboratorAgreements = (collaborator) => {
    return async(dispatch) => {
        try {
            await dispatch(agreePrevStartLoading(collaborator.id));
            dispatch(agreementSetActive(collaborator));
        } catch (error) {
           console.error(error) ;
        }
    }
    
};

export const agreeStartLoading = () => {
    return async(dispatch, getState) => {
        const { activeEvaluator } = getState().evaluator;
        try {
                const resp = await fetchConToken(`agreements/list/${activeEvaluator[0].id}`);
                const body = await resp.json();
                const agreements = body.agreements;
                dispatch(agreeLoaded(agreements));
            } catch (error) {
            console.error(error);
        }
    }
}
export const agreementSetActive = (collaborator) => ({
    type: agreementsType.collaborator,
    payload: collaborator
});



const agreeLoaded = (agreements) => ({
    type: agreementsType.listTeam,
    payload: agreements,
})

export const openMagreements = () => ({type: agreementsType.openMagreements});
export const closeMagreements = () => ({type: agreementsType.closeMagreements});
export const clearAgreements = () => ({type: agreementsType.clearCollaborator});