import { goalsTypes } from "./goalsTypes";

const initialState = {
    activeGoal: null,
    goals: []
}

export const goalsReducer = (state = initialState, action ) => {
    switch (action.type) {
        case goalsTypes.activeGoal:
            return{
                ...state,
                activeGoal: action.payload
            }
        case goalsTypes.clearGoal:
            return{
                ...state,
                activeGoal: null
            }
        case goalsTypes.addGoal:
            return{
                ...state,
                activeGoal: action.payload,
                goals: [
                    ...state.goals,
                    action.payload
                ]
            }
        case goalsTypes.editGoal:
            return {
                ...state,
                goals: state.goals.map(
                    g=>(g.id === action.payload.id ? action.payload : g)
                )
            }
        default:
            return state;
     }
};
