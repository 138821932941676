import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Subtitle } from "../ui/subtitle/Subtitle";
import { Lists } from "../ui/lists/Lists";
import { agreeStartLoading, openMagreements } from "./agreementsActions";
import { AgreementModal } from "./AgreementModal";

export const AgreementScreen = () => {
  const dispatch = useDispatch();
  const {agreeList} = useSelector(state => state.agreements);
  useEffect(() => {
    dispatch(agreeStartLoading());
  }, [dispatch]);
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value="Concertación" />
        <Lists rows={agreeList} edit={true} idList='agreeList' openModal={openMagreements()}/>
      </div>
      <BackButton path="/main" />
      <AgreementModal/>
    </div>
  );
};