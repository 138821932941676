import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { areaTypes } from "./areaTypes";
export const areaStartAddNew = (area) => {
    return async( dispatch, getState) => {
        //const { id } = getState().auth;
        try {
            const resp = await fetchConToken('areas/add', area, 'POST');
            const body = await resp.json();
            if(body.area === 'add'){
                area.id = body.id;
                dispatch(areaAddNew(area));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const areaAddNew = (area) => ({
    type: areaTypes.areaAddNew,
    payload: area
});

export const areaSetActive = (area) => ({
    type: areaTypes.areaSetActive,
    payload: area
});

export const areaClearActiveArea = () => ({
    type: areaTypes.areaClearActiveArea,
});

export const areaStartUpdate = ( area ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`areas/update/${ area.id }`, area, 'PUT' );
            const body = await resp.json();

            if ( body.areas !== 'null' ) {
                dispatch( areaUpdated( area ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const areaUpdated = (area) => ({
    type: areaTypes.areaUpdated,
    payload: area
});

export const areaDeleted = (area) => ({ type: areaTypes.areaDeleted });

export const areaStartLoading = () => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`areas/${ configuration.year }`);
                const body = await resp.json();
                const areas = body.areas;
                dispatch(areaLoaded(areas));
            } catch (error) {
            console.error(error);
        }
    }
}

const areaLoaded = (areas) => ({
    type: areaTypes.areaLoaded,
    payload: areas,
})

export const OpenMArea = () => ({ type: areaTypes.OpenMArea });
export const CloseMArea = () => ({ type: areaTypes.CloseMArea });
