import React from 'react';
import {configuration} from '../../../types/conf';
import { CloseButton } from '../../buttons/close/CloseButton';

export const Subtitle = ({value, close, fclose}) => {
    return (
        <div className="w-100 p-2 fw-bold" 
        style={{
            backgroundColor: configuration.mainColor,
            color: configuration.textMainColor,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            }}>
            <div>{value}</div>
            { close ? <CloseButton close={fclose}/> : <></> }
            
        </div>
    )
}
