import React from 'react'
import { configuration } from "../../types/conf";

export const Review = ({commitments}) => {
    var idObje = 0;
    var printObje = true;
    var idGoal = 0;
    var printGoal = true;
    var actColor = 'white';
  return (
    <div>
        {
            commitments.map((c, index) => {
                printObje = (idObje === c.idObjective) ? false : true;
                idObje = c.idObjective;
                printGoal = (idGoal === c.idGoal) ? false : true;
                idGoal = c.idGoal;
                actColor = (index % 2);
                return(
                  <div className='container '>
                    {
                      (printObje)
                      ? <div className='row bg-secondary text-light'><strong>Objetivo:</strong>{c.objective}</div>
                      : <></>
                    }
                    {
                      (printGoal)
                      ? <div className='row' style={{backgroundColor: configuration.grayColor}}><div className='col-10'><strong>Meta:</strong>{c.goal}</div><div className='col-2 '><h3>{c.percent}%</h3></div></div>
                      : <></>
                    }
                    {
                       (printGoal)
                       ? <div className='row text-center ' style={{backgroundColor: configuration.rowColor}}>
                         <div className='col'> <strong>Actividad</strong></div>
                          <div className='col'><strong>Evidencia</strong></div>
                          <div className='col'> <strong>Fecha</strong></div>
                       </div>
                       : <></>
                    }
                    <div className='row'>
                      <div className='col'> {c.activity}</div>
                      <div className='col'>{c.evidence}</div>
                      <div className='col'>{c.start} al {c.end}</div>
                      <hr />
                      </div>
                  </div>
                )
              })
        }
    </div>
  )
}
