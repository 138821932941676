import { activitesTypes } from "./activitiesTypes";

const initialState = {
    activities: [],
    activeActivity: null,
    loadingActivities: true,
    modalOpen: false
}
export const activitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case activitesTypes.activities:
            return {
                ...state,
                activities: action.payload,
                loadingActivities: false,
            }
        case activitesTypes.OpenMActivity:
            return {
                ...state,
                modalOpen: true
            }
        case activitesTypes.CloseMActivity:
            return {
                ...state,
                modalOpen: false
            }
        case activitesTypes.clearActivity:
            return {
                ...state,
                activeActivity: null
            }
        case activitesTypes.addActivity:
            if (state.activities === []) {
                return {
                    ...state,
                    activities: [action.payload],
                    activeActivity: null
                }
            }
            else {
                return {
                    ...state,
                    activities: [
                        ...state.activities,
                        action.payload
                    ],
                    activeActivity: null
                }
            }
        case activitesTypes.setActivity:
            return{
                ...state,
                activeActivity: action.payload
            }
        case activitesTypes.editActivity:
            return {
                ...state,
                activities: state.activities.map(
                    a=>(a.id === action.payload.id ? action.payload : a)
                ),
                activeActivity: null
            }
        default:
            return state;
    }
};
