export const configuration = {
    mainColor: "#136b7c",
    textMainColor: "#FFFFFF",
    navBarColor: "#000",
    rowColor: "#bad0fc",
    inactiveColor: "#eda3b6",
    year: 2022,
    greenColor: "green",
    redColor: "red",
    grayColor: "#d9d5d4",
}
