import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { collaboratorClear } from "../evaluators/evaluatorActions";
import { generalTypes } from "./generalTypes";

export const generalSteps = (general, agreements = false) => {
    return async (dispatch, getState) => {
        const {id} = getState().auth;
        try {
            const resp = await fetchConToken(`collaborators/steps/${configuration.year}/${id}`, general, 'POST');
            const body = await resp.json();
            if(body.step === 'add'){
                dispatch(generalAddStep(general));
                if(agreements === true)
                    dispatch(collaboratorClear());
            }
               
        } catch (error) {
            console.error(error);
        }
    }
}

const generalAddStep = (general) => ({
    type: generalTypes.stepGeneral,
    payload: general
});

export const generalStartLoading = (user) => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`collaborators/${ configuration.year }/${user}`);
                const body = await resp.json();
                const colaborators = body.colaborators;
                dispatch(generalLoaded(colaborators));
            } catch (error) {
            console.error(error);
        }
    }
}

export const generalStartAddNew = (general) => {
    return async( dispatch, getState) => {
        //const { id } = getState().auth;
        try {
            const resp = await fetchConToken('general/add', general, 'POST');
            const body = await resp.json();
            if(body.general === 'add'){
                dispatch(generalAddNew(general));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const generalAddNew = (general) => ({
    type: generalTypes.generalAddNew,
    payload: general
});

const generalLoaded = (colaborators) => ({
    type: generalTypes.collaboratorLoaded,
    payload: colaborators,
});

export const generalStepsLoading = (collaborator) => {
    return async(dispatch)=>{
        try {
            const resp = await fetchConToken(`collaborators/steps/${ configuration.year }/${collaborator}`);
            const body = await resp.json();
            if(body){
                await dispatch(stepLoaded(body.steps));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const stepLoaded = (steps) => ({
    type: generalTypes.stepsLoaded,
    payload: steps,
});
