import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {configuration} from '../../../types/conf';
import './back.css';
import { UpdateBack } from './backActions';

export const BackButton = ({path}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(UpdateBack(path));
     }, [dispatch]);
    const { history } = useSelector( state => state.back );
    const back = useHistory();
    const handleClickNew = () => {
        back.push(history);
    }

    return (
        <button
            className="btn btn-fab"
            style={{backgroundColor: configuration.navBarColor}}
            onClick={ handleClickNew }
        >
            <i 
                className="fas fa-chevron-left"
                style={{color: configuration.textMainColor}}
            ></i>
        </button>
    )
}
