import { evaluatorTypes } from "./evaluatorTypes"


const initialState = {
    evaluators: [],
    colaborators: [],
    checking: true,
    checkColaborators: true,
    activeEvaluator: null,
    collaborator: null,
}

export const evaluatorReducer = (state = initialState, action) => {
    switch (action.type) {
        case evaluatorTypes.evaluatorSetActive:
            return{
                ...state,
                activeEvaluator: action.payload
            }
        case evaluatorTypes.collaboratorSetActive:
            return {
                ...state,
                collaborator: action.payload
            }
        case evaluatorTypes.evaluatorAddNew:
            return{
                ...state,
                colaborators: state.colaborators.map(
                    b => (b.id === action.payload.colaborator) ? action.payload : b
                ),
                collaborator: null
            }
        case evaluatorTypes.evaluatorClearActiveEvaluator:
            return{
                ...state,
                activeEvaluator: null
            }
        case evaluatorTypes.evaluatorUpdated:
            return{
                ...state,
                evaluators: state.evaluators.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case evaluatorTypes.evaluatorDeleted:
            return{
                ...state,
                evaluators: state.evaluators.filter(
                    b => (b.id !== state.activeEvaluator.id)
                ),
                activeEvaluator: null
            }
        case evaluatorTypes.evaluatorLoaded:
            return{
                ...state,
                evaluators: action.payload,
                checking: false
            }
        case evaluatorTypes.colaboratorLoaded:
            return{
                ...state,
                colaborators: action.payload,
                checkColaborators: false
            }
        case evaluatorTypes.OpenMEvaluator:
            return {
                ...state,
                modalOpenG: true
            }
        case evaluatorTypes.CloseMEvaluator:
            return {
                ...state,
                 modalOpenG: false
             }
        case evaluatorTypes.checkColaborators:
            return{
                ...state,
                checkColaborators: true
            }
        case evaluatorTypes.colaboratorClear:
            return {
                ...state,
                collaborator: {
                    id: null,
                    name: null
                }
            }
        default:
            return state;
    }
}
