import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { rankTypes } from "./rankTypes";
export const rankStartAddNew = (rank) => {
    return async( dispatch) => {
        try {
            const resp = await fetchConToken('ranks/add', rank, 'POST');
            const body = await resp.json();
            if(body.ranks === 'add'){
                rank.id = body.id;
                dispatch(rankAddNew(rank));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const rankAddNew = (rank) => ({
    type: rankTypes.rankAddNew,
    payload: rank
});

export const rankSetActive = (rank) => ({
    type: rankTypes.rankSetActive,
    payload: rank
});

export const rankClearActiveRank = () => ({
    type: rankTypes.rankClearActiveRank,
});

export const rankStartUpdate = ( rank ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`ranks/update/${ rank.id }`, rank, 'PUT' );
            const body = await resp.json();

            if ( body.ranks !== 'null' ) {
                dispatch( rankUpdated( rank ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const rankUpdated = (rank) => ({
    type: rankTypes.rankUpdated,
    payload: rank
});

export const rankDeleted = (rank) => ({ type: rankTypes.rankDeleted });

export const rankStartLoading = (position) => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`ranks/${ position }`);
                const body = await resp.json();
                const ranks = body.ranks;
                dispatch(rankLoaded(ranks));
            } catch (error) {
            console.error(error);
        }
    }
}

const rankLoaded = (ranks) => ({
    type: rankTypes.rankLoaded,
    payload: ranks,
})

export const OpenMRank = () => ({ type: rankTypes.OpenMRank });
export const CloseMRank = () => ({ type: rankTypes.CloseMRank });
