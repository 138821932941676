import { profileTypes } from "./profileTypes"


const initialState = {
    active: null,
}

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {   
        case profileTypes.typeUser:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}