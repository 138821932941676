
const baseUrl = process.env.REACT_APP_URL_API;


const fetchSinToken = (endpoint, data, method = 'GET') => {
    const url = `${ baseUrl}/${ endpoint}`;
    if(method === 'GET'){
        return fetch(url);
    } else {
      return fetch(url, {
          method,
          headers: {
              'Content-Type': 'application/json',    
          },
          body: JSON.stringify(data),
      });
          
    }
}
const fetchConToken = (endpoint, data, method = 'GET') => {
    const url = `${ baseUrl}/${ endpoint}`;
    const token = localStorage.getItem('token') || '';
    if(method === 'GET'){
        return fetch(url, {
            method,
            headers: {
                'x-code': token,
            },
            body: JSON.stringify(data),
        });
    } else {
      return fetch(url, {
          method,
          headers: {
              'Content-Type': 'application/json',
              'x-code': token,  
          },
          body: JSON.stringify(data),
      });
          
    }
}
export {
    fetchSinToken,
    fetchConToken
}