import { combineReducers } from "redux";
import { areaReducer } from "../component/areas/areaReducer";
import { backReducer } from "../component/buttons/back/backReducer";
import { competencieReducer } from "../component/competencies/competencieReducer";
import { rankReducer } from "../component/rank/rankReducer";
import { gradeReducer } from "../component/grades/gradeReducer";
import { levelReducer } from "../component/levels/levelReducer";
import { positionReducer } from "../component/positions/positionReducer";
import { profileReducer } from "../component/profile/profileReducer";
import { authReducer } from "./authReducer";
import { commonReducer } from "../component/commons/commonReducer";
import { functionalReducer } from "../component/functional/functionalReducer";
import { teamReducer } from "../component/teams/teamReducer";
import { evaluatorReducer } from "../component/evaluators/evaluatorReducer";
import { generalReducer } from "../component/general/generalReducer";
import { commimentReducer } from "../component/commitments/commimentReducer";
import { goalsReducer } from "../component/goals/goalsReducer";
import { objectiveReducer } from "../component/objectives/objectiveReducer";
import { activitiesReducer } from "../component/activities/activitiesReducer";
import { trainingReducer } from "../component/training/trainingReducer";
import { userReducer } from "../component/users/userReducer";
import { agreementsReducer } from "../component/agreements/agreementsReducer";
import { previewReducer } from "../component/preview/previewReducer";
import { helpReducer } from "../component/buttons/help/helpReducer";
import { selfAssesmentReducer } from "../component/SelfAssessment/selfAssesmentReducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    typeuser: profileReducer,
    back: backReducer,
    grade: gradeReducer,
    team: teamReducer,
    level: levelReducer,
    area: areaReducer,
    position: positionReducer,
    competencie: competencieReducer,
    rank: rankReducer,
    common: commonReducer,
    functional: functionalReducer,
    evaluator: evaluatorReducer,
    general: generalReducer,
    commitment: commimentReducer,
    goals: goalsReducer,
    objectives: objectiveReducer,
    activities: activitiesReducer,
    training: trainingReducer,
    users: userReducer,
    agreements: agreementsReducer,
    preview: previewReducer,
    help: helpReducer,
    self: selfAssesmentReducer,
})
