import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles.css';
import { configuration } from '../../types/conf';
import { MainButton } from '../buttons/main/MainButton';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { CloseMCommon, commonClearActiveCommon, commonStartAddNew, commonStartUpdate } from './commonActions';
const customStyles={
    content: {
        top        : 'auto',
        left       : 'auto',
        right      : 'auto',
        bottom     : 'auto',
        marginRight: '0%',
        Transform  : 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initCommon = {
    name: '',
    behaviours: '',
    position: 1,
    year: configuration.year,
    active: 1,
}
export const CommonModal = ({position}) => {
    initCommon.position= position;
    const { activeCommon, modalOpenG }  = useSelector(state => state.common);
    const [formValues, setFormValues] = useState(initCommon);
    const [titleValid, settitleValid] = useState(true);
    const dispatch = useDispatch();
    const {name, active, behaviours, year } = formValues;
    useEffect(() => {
        if(activeCommon) {
            setFormValues(activeCommon);
        }else {
            setFormValues(initCommon);
        }
    }, [activeCommon, setFormValues]);
    const closeModalG = () => {
        dispatch( CloseMCommon());
        dispatch(commonClearActiveCommon());
        setFormValues(initCommon);
    }
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) =>{
        e.preventDefault();

        if(name.trim().length < 1 ){
            settitleValid(false);
            return;
        }
        if( activeCommon) {
            dispatch(commonStartUpdate(formValues));
        } else {
            dispatch(commonStartAddNew(formValues));
        }
        settitleValid(true);
        closeModalG();
    }
    return (
        <Modal
            isOpen={modalOpenG}
            onRequestClose={closeModalG}
            style={customStyles}
            closeTimeoutMS={200}
            className='modal'
            overlayClassName='modal-fondo'
        >
            <Subtitle value={ (activeCommon) ? 'Editar Competencia Común' : 'Crear Competencia Común'} close={true} fclose={closeModalG}/>
            <form
            className="container"
            onSubmit={handleSubmitForm}
            >
            <div className="form-group">
                <label>Nombre Competencia</label>
                <input
                    type="text"
                    className={`form-control ${!titleValid && 'is-invalid'}`}
                    placeholder="Nombre"
                    name="name"
                    autoComplete="off"
                    value={name}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted">Nombre de la competencia</small>
            </div>
            <div className="form-group">
                <label>Comportamientos</label>
                <textarea
                    className={`form-control ${!titleValid && 'is-invalid'}`}
                    placeholder="Comportamientos..."
                    name="behaviours"
                    autoComplete="off"
                    value={behaviours}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted">Nombre de la competencia</small>
            </div>
            <div className="form-group">
                <label>Activo</label>
                <select
                    className="form-select"
                    aria-label="Active"
                    name="active"
                    value={active}
                    onChange={handleInputChange}
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Esta activo?</small>
            </div>
            <div className="form-group">
                <label>Año</label>
                <select
                    className="form-select"
                    aria-label="Year"
                    name="year"
                    value={year}
                    onChange={handleInputChange}
                    >
                        <option value={configuration.year}>{configuration.year}</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Año del nivel</small>
            </div>
            <br />
            <MainButton
                nameButton="sCommon"
                idButton="sCommon"
                valueButton=" Guardar"
                handleClick={handleSubmitForm}
                icon="far fa-save"
            />

            </form>
        </Modal>
    )
}
