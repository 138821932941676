import React from 'react';
import { NavBar } from '../navbar/NavBar';
import { useHistory } from 'react-router';
import { DashboardAdmin } from './DashboardAdmin';
import { useSelector } from 'react-redux';
import { DashboardCollaborator } from './DashboardCollaborator';
import { DashboardEvaluator } from './DashboardEvaluator';

export const DashboardScreen = () => {
    const { type } = useSelector(state => state.auth);
    const router = useHistory();
    let usertype = '';
    if(type === 'administrator'){
        usertype = 'Administrador';
        const handleClick = ({target}) =>{
            router.push(target.id);
        }
        return(
            <div>
            <NavBar/>
                <div className="container">
                    <DashboardAdmin
                        handleClick={handleClick}
                        router={router}
                    />
                </div>
            </div>
        );
    } else if(type === 'evaluator'){
        usertype = 'Evaluador';
        const handleClick = ({target}) =>{
            router.push(target.id);
        }
        return(
            <div>
            <NavBar/>
                <div className="container">
                  <DashboardEvaluator
                        handleClick={handleClick}
                        router={router}
                    />
                </div>
            </div>
        );
    } else if(type === 'collaborator'){
        usertype = 'Colaborador';
        const handleClick = ({target}) =>{
            router.push(target.id);
        }
        return(
            <div>
            <NavBar/>
                <div className="container">
                   <DashboardCollaborator
                        handleClick={handleClick}
                        router={router}
                    />

                </div>
            </div>
        );

    } else {
        return(
            <div>
                <NavBar/>
                Acceso no autorizado por favor Salga y vuelva a ingresar
            </div>
        );
    }
}
