export const generalTypes = {
    collaboratorLoaded: '[general] Collaborator Loaded',
    statusGeneral: '[general] Set right or wrong general',
    collaboratorSetActive: '[general] Set active collaborator',
    stepGeneral: '[general] Set step process',
    stepsLoaded: '[general] Steps Loaded',
    evaluatorLoaded: '[general] Evaluator Loaded',
    evaluatorSetActive: '[general] Set active evaluator',
    generalAddNew: '[general] Form General',
}
