import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Subtitle } from "../ui/subtitle/Subtitle";
import { AddButton } from "../buttons/add/AddButton";
import { Lists } from "../ui/lists/Lists";
import { positionStartLoading, OpenMPosition } from "./positionActions";
import { PositionModal } from "./PositionModal";

export const PositionScreen = () => {
  const dispatch = useDispatch();
  const {positions} = useSelector(state => state.position);
  useEffect(() => {
      dispatch(positionStartLoading());
  }, [dispatch]);
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value="Cargos" />
        <Lists rows={positions} edit={true} info={true} infoPath='/competencies' idList='positionList' openModal={OpenMPosition()}/>
      </div>
      <BackButton path="/main" />
      <AddButton open={OpenMPosition()} />
      <PositionModal/>
    </div>
  );
};
