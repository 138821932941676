import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";

import { Subtitle } from "../ui/subtitle/Subtitle";
import { AddButton } from "../buttons/add/AddButton";
import { Lists } from "../ui/lists/Lists";
import { functionalStartLoading, OpenMFunctional } from "./functionalActions";
import { FunctionalModal } from "./FunctionalModal";
import { NavBar } from "../navbar/NavBar";

export const FunctionalScreen = () => {
  const { activePosition}  = useSelector(state => state.position);
  const [dataPosition] = useState(activePosition);
  const {id, name} = dataPosition;
  const dispatch = useDispatch();
  const {functionals} = useSelector(state => state.functional);
  useEffect(() => {
      dispatch(functionalStartLoading(id));
  }, [dispatch]);
  return (
    <div>
    <NavBar/>
      <div className="container">
        <Subtitle value={name}/>
        <Subtitle value="Competencias Funcionales" />
        <Lists rows={functionals} edit={true} idList='functionalList' openModal={OpenMFunctional()}/>
      </div>
      <BackButton path="/competencies" />
      <AddButton open={OpenMFunctional()} />
      <FunctionalModal position={id}/>
    </div>
  );
};
