import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { goalsTypes } from "./goalsTypes";

export const goalStartAdd = (goal) => {
    return async (dispatch, getState) => {
        const { collaborator } = getState().general;
        goal.collaborator = collaborator[0].id;
        try {
            const resp = await fetchConToken(`goal/add`,goal, 'POST');
            const body = await resp.json();
            if(body.goals === 'add'){
                goal.id = body.id;
                dispatch(goalAdd(goal));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const goalAdd = (goal) => ({
    type: goalsTypes.addGoal,
    payload: goal
})

export const goalSetActive = (goal) => ({
    type: goalsTypes.activeGoal,
    payload: goal
});

export const goalStartUpdate = (goal) => {
    return async (dispatch, getState) => {
        const { collaborator } = getState().general;
        goal.collaborator = collaborator[0].id;
        try {
            const resp = await fetchConToken(`goals/update/${goal.id}`,goal, 'PUT');
            const body = await resp.json();
            if(body.goals !== 'null'){
                dispatch(goalUpdated(goal));
            } else {
                Swal.fire('Error', body.error, 'error');
            }
        } catch (error) {
            console.error(error);
        }
    }
}
const goalUpdated = (goal) => ({
    type: goalsTypes.editGoal,
    payload: goal
});
