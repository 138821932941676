import { commitmenType } from "./commimenType";

const initialState = {
    goalOption: 'open',
    active: [],
    loading: true,
    loadGoals: true,
    goals: []
}

export const commimentReducer = (state = initialState, action) => {
  switch (action.type) {
    case commitmenType.goalAction:
        return{
            ...state,
            goalOption: action.payload
        }
    case commitmenType.commitment:
        return{
            ...state,
            active: action.payload,
            loading: false
        }
    case commitmenType.goals:
        return{
            ...state,
            goals: action.payload,
            loadGoals: false
        }
    default:
        return state;
  }
};
