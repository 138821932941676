import { fetchSinToken, fetchConToken } from "../helpers/fetch";
import { types } from "../types/types";
import Swal from "sweetalert2";

export const startChecking = () =>{
    return async (dispatch) => {
        const resp = await fetchConToken('user/upjwt');
        const string = await resp.text();
        const body = string === "" ? {} : JSON.parse(string);

        if(body.id !== undefined && body.id !== 'null'){
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch( login({
                id: body.id,
                name: body.name,
                type: body.type
            }))
        } else {
            dispatch(checkingFinish());
        }
    }
}
export const startLogin = (email, password) => {
    return async(dispatch, getState) => {
        const { active } = getState().typeuser;
        const resp = await fetchSinToken('users/login', {email, password, active}, 'POST');
        const string = await resp.text();
        const body = string === "" ? {} : JSON.parse(string);
        
        if(body.id !== undefined && body.id !== 'null'){
            localStorage.setItem('token', body.token);
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch( login({
                id: body.id,
                name: body.name,
                phone: body.phone,
                type: body.type
            }));
        } else {
            Swal.fire('Error', body.error, 'error');
        }
    }
}

const login = ( user ) => ({
    type: types.Login,
    payload: user
});

const checkingFinish = () => ({ type: types.authCheckingFinish });

export const startLogout = () => {
    return ( dispatch ) => {
        localStorage.clear();
        dispatch( logout() );
    }
}

const logout = () => ({ type: types.authLogout })