import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Agreement } from '../agreements/Agreement';
import { generalStepsLoading } from '../general/generalActions';
import { NavBar } from '../navbar/NavBar';
import { Subtitle } from '../ui/subtitle/Subtitle'

export const RoutingEvaluator = () => {
    const { collaborator } = useSelector(state => state.evaluator);
    const {steps} = useSelector(state => state.general);
    const dispatch = useDispatch();
    useEffect(() => {
            dispatch(generalStepsLoading(collaborator.id));
    }, [dispatch]);
    if(steps){
        let done = [];
        let next = false;
        var message = '';
        for(let i = 0; i < steps.length; i++){
            done[steps[i].step] = true;
        }
        for (let s = 1; s <= 4; s++){
            if(!done[s] && s === 1 && next === false){
                message = 'El evaluado no esta de acuerdo con los datos Generales :(';
                next = true;
            }
            if(!done[s] && s === 2 && next === false){
                message = 'El evaluado no ha completado el 100% de las metas a comprometer :(';
                next = true;
            }
            if(!done[s] && s === 3 && next === false){
                message = 'El evaluado no ha cargado el plan de capacitacion :(';
                next = true;
            }
            if(!done[s] && s === 4 && next === false){
                next = true;
            }
        }
    }
    return (
        <div>
            <NavBar />
            <div className='container'>
                <Subtitle value={'Evaluado seleccionado: '+collaborator.name} />
                {
                    (message !== '')
                    ? <div className='alert-danger text-center m-5 p-5'><h4>{message}</h4></div>
                    : <Agreement/>
                }
            </div>
        </div>
    )
}
