import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { evaluatorTypes } from "./evaluatorTypes";

export const evaluatorStartAddNew = (evaluator) => {
    return async( dispatch) => {
        try {
            const resp = await fetchConToken('evaluators/add', evaluator, 'POST');
            const body = await resp.json();
            if(body.evaluator === 'add'){
                const collaboratorUpdated = {
                    id: evaluator.colaborator,
                    name: evaluator.name,
                    evaluator: evaluator.evaluator
                }
                dispatch(evaluatorAddNew(collaboratorUpdated));
                dispatch(colaboratorStartLoading(collaboratorUpdated.evaluator, false));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const evaluatorAddNew = (evaluator) => ({
    type: evaluatorTypes.evaluatorAddNew,
    payload: evaluator
});

export const evaluatorSetActive = (evaluator) => ({
    type: evaluatorTypes.evaluatorSetActive,
    payload: evaluator
});

export const setCollaboratorActive = (collaborator) => {
    collaboratorUpdated(collaborator.id);
};

export const evaluatorClearActiveevaluator = () => ({
    type: evaluatorTypes.evaluatorClearActiveevaluator,
});

export const evaluatorStartUpdate = ( evaluator ) => {
    return async(dispatch) => {
        try {
            const resp = await fetchConToken(`evaluators/update/${ evaluator.id }`, evaluator, 'PUT' );
            const body = await resp.json();

            if ( body.evaluators !== 'null' ) {
                dispatch( evaluatorUpdated( evaluator ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }
        } catch (error) {
            console.error(error)
        }

    }
}

export const getTeamEvaluator = () => {
    return async (dispatch, getState) => {
        const user = getState().auth.id;
        try {
            const resp = await fetchConToken(`teams/${configuration.year}/${user}`);
            const body = await resp.json();
            if(body.team){
                dispatch(evaluatorSetActive(body.team));
            }else {
                Swal('No hace parte del equipo activo verifica con talento humano.');
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const evaluatorUpdated = (evaluator) => ({
    type: evaluatorTypes.evaluatorUpdated,
    payload: evaluator
});

export const collaboratorUpdated = (collaborator) => ({
    type: evaluatorTypes.collaboratorSetActive,
    payload: collaborator
});

export const collaboratorClear = () => ({ type: evaluatorTypes.colaboratorClear });

export const evaluatorDeleted = (evaluator) => ({ type: evaluatorTypes.evaluatorDeleted });

export const evaluatorStartLoading = (evaluator) => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`evaluators/${ configuration.year }`);
                const body = await resp.json();
                const evaluators = body.evaluators;
                dispatch(evaluatorLoaded(evaluators));
            } catch (error) {
            console.error(error);
        }
    }
}

const evaluatorLoaded = (evaluators) => ({
    type: evaluatorTypes.evaluatorLoaded,
    payload: evaluators,
})

export const clearCheckColaborators = () => ({type: evaluatorTypes.checkColaborators});

export const colaboratorStartLoading = (evaluator, strict = false) => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`evaluators/${ evaluator }/${strict}`);
                const body = await resp.json();
                const colaborators = body.colaborators;
                dispatch(colaboratorLoaded(colaborators));
            } catch (error) {
            console.error(error);
        }
    }
}

const colaboratorLoaded = (colaborators) => ({
    type: evaluatorTypes.colaboratorLoaded,
    payload: colaborators,
})
export const OpenMevaluator = () => ({ type: evaluatorTypes.OpenMevaluator });
export const CloseMevaluator = () => ({ type: evaluatorTypes.CloseMevaluator });
