export const objectiveTypes = {
    OpenMObjective: '[objective] is Open Modal',
    CloseMObjective: '[objective] is close Modal',
    activeObjective: '[objective] Set active objective',
    clearObjective: '[objective] Clear active objective',
    typesObjective: '[objectives] Get typeobjectives',
    objectives: '[goals] Get objectives',
    addObjectives: '[objectives] Add new objective',
    editObjectives: '[objectives] Edit an objective',
}
