import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { goalsTypes } from "../goals/goalsTypes";
import { objectiveTypes } from "./objectiveTypes";

export const OpenMObjective = () => ({ type: objectiveTypes.OpenMObjective });
export const CloseMObjective = () => ({ type: objectiveTypes.CloseMObjective });
export const objectiveSetActive = (objective) => ({
    type: objectiveTypes.activeObjective,
    payload: objective
});
export const objectiveClearActive = () => ({ type: objectiveTypes.clearObjective });
export const getTypeObjectives = () => {
    return async (dispatch) =>{
        try {
            const resp = await fetchConToken(`objectives/type/${configuration.year}`);
            const body = await resp.json();
            if(body.typeobjectives){
                dispatch(typeobjectivesLoaded(body.typeobjectives));
            }
            
        } catch (error) {
            console.error(error);
        }
    };
};

export const objectiveStartAdd = (objective) => {
    return async (dispatch, getState) => {
        const { collaborator } = getState().general;
        objective.collaborator = collaborator[0].id;
        try {
            const resp = await fetchConToken(`objective/add`,objective, 'POST');
            const body = await resp.json();
            if(body.objectives === 'add'){
                objective.id = body.id;
                dispatch(objectiveAdd(objective));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const objectiveAdd = (objective) => ({
    type: objectiveTypes.addObjectives,
    payload: objective
})

export const objectiveStartUpdate = (objective) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken(`objectives/update/${objective.id}`,objective, 'PUT');
            const body = await resp.json();
            if(body.objectives !== 'null'){
                dispatch(objectiveUpdated(objective));
            } else {
                Swal.fire('Error', body.error, 'error');
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export const objectiveStartLoading = (id) => {
    return async(dispatch) => {
        try {
            const resp = await fetchConToken(`objectives/${id}`);
            const body = await resp.json();
            dispatch(objectivesLoaded(body.objectives));
        } catch (error) {
            console.error(error);
        }
    }
}

const objectivesLoaded = (objectives) => ({
    type: objectiveTypes.objectives,
    payload: objectives
});


const typeobjectivesLoaded = (typeobjectives) => ({
    type: objectiveTypes.typesObjective,
    payload: typeobjectives
});

const objectiveUpdated = (objective) => ({
    type: objectiveTypes.editObjectives,
    payload: objective
});
