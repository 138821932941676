import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton } from '../buttons/add/AddButton';
import { BackButton } from '../buttons/back/BackButton';
import { NavBar } from '../navbar/NavBar';
import { Lists } from '../ui/lists/Lists';
import { Loading } from '../ui/loading/Loading';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { getTypeObjectives, OpenMObjective } from './objectiveActions';
import { ObjectiveModal } from './ObjectiveModal';

export const ObjectiveScreen = () => {
  const {typeobjectives, objectives} = useSelector(state => state.objectives);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTypeObjectives());
  }, [dispatch]);
  if(typeobjectives === []){
    return <Loading/>;
  }
  return (
    <div>
        <NavBar/>
        <div className='container'>
          <Subtitle value={'Objetivos'}/>
          {
            (objectives != null)
            ? <Lists rows={objectives} edit={true} idList='objectiveList' openModal={OpenMObjective()} />
            : <></>
          }
        </div>
        <BackButton path="/goals" />
        <AddButton open={OpenMObjective()} />
        <ObjectiveModal/>
    </div>
  );
}
