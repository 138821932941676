import React, { useEffect } from 'react';
import { AddButton } from '../buttons/add/AddButton';
import { BackButton } from '../buttons/back/BackButton';
import { NavBar } from '../navbar/NavBar';
import { Lists } from '../ui/lists/Lists';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { configuration } from '../../types/conf';
import { MainButton } from '../buttons/main/MainButton';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setGoalOptions, commitmentStartLoading, goalStartLoading } from './commitmentActions';
import { Loading } from '../ui/loading/Loading';
import { goalSetActive } from '../goals/goalsActions';
import { generalSteps } from '../general/generalActions';
import { HelpButton } from '../buttons/help/HelpButton';


export const CommitmentsScreen = () => {
  const { active, loading, loadGoals, goals } = useSelector(state => state.commitment);
  const { collaborator } = useSelector(state => state.general);
  const collab = collaborator[0];
  const router = useHistory();
  const dispatch = useDispatch();
  
  const handleAgree = (e) =>{
    e.preventDefault();
   // dispatch(generalSteps(updState));
    router.push('/review');
  }
  const openGoals = (e) => {
    e.preventDefault();
    dispatch(setGoalOptions('open'));
    router.push('/goals');
  }
  const editGoals = (e) => {
    dispatch(setGoalOptions('edit'));
    router.push('/goals');
  }
  useEffect(() => {
    dispatch(commitmentStartLoading(collab.id));
    dispatch(goalStartLoading(collab.id));
  }, [dispatch]);
  if (loading || loadGoals) {
    return <Loading />;
  }
  return (<div>
    <NavBar />
    <div className="container">
      <Subtitle value="Paso 2 Compromisos - Metas" />
      {goals !== 'null'
        ? <Lists rows={goals} edit={true} idList='commitList' openModal={editGoals} modal={false} />
        : <></>
      }
      <br/>
      <div className="progress" style={{height: '20px'}}>
        <div className="progress-bar" role="progressbar" style={{backgroundColor:'green', width: `${active[0].used}%`}}>
          <span style={{ color: configuration.mainColor, fontSize: '1.5em' }} className="text-end fw-bolder text-light">{active[0].used}%</span>
        </div>
      </div>
      <br/>
      {active[0].used === '100'
        ? <MainButton
          nameButton='step2end'
          idButton='step2end'
          valueButton='  Dar clic para validar la información ingresada'
          handleClick={handleAgree}
          icon="fas fa-check-double"
          back={configuration.redColor}
        />
        : <></>
      }
    </div>
    
    <HelpButton type='pdf' path='https://s3.amazonaws.com/rtvc-assets-intranet.rtvc.gov.co/field/fileDocument/Calendario%20Planeaci%C3%B3n%202022.pdf'/>
    <BackButton path="/main" />
    <AddButton open={openGoals} modal={false} />
  </div>);
};
