import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Subtitle } from "../ui/subtitle/Subtitle";
import { AddButton } from "../buttons/add/AddButton";
import { Lists } from "../ui/lists/Lists";
import { areaStartLoading, OpenMArea } from "./areaActions";
import { AreaModal } from "./AreaModal";

export const AreaScreen = () => {
  const dispatch = useDispatch();
  const {areas} = useSelector(state => state.area);
  useEffect(() => {
      dispatch(areaStartLoading());
  }, [dispatch]);
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value="Areas" />
        <Lists rows={areas} edit={true} idList='areaList' openModal={OpenMArea()}/>
      </div>
      <BackButton path="/main" />
      <AddButton open={OpenMArea()} />
      <AreaModal/>
    </div>
  );
};
