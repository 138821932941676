import React from "react";
import { useDispatch } from "react-redux";

export const EditButton = ({ nameButton, idButton, update, setActive, openModal, modal=true }) => {
  const dispatch = useDispatch();

  function handleUpdate(e) {
    e.preventDefault();
    dispatch(setActive);
    if(modal === true){
      dispatch(openModal);
    } else {
      openModal();
    }
      
  }
  return (
    <button
      className="btn btn-success"
      name={nameButton}
      id={idButton}
      onClick={handleUpdate}
      //onClick={modal === true ? handleUpdate : openModal}
      style={{ paddingBottom: "8px" }}
    >
      <i className="fas fa-edit"></i>
    </button>
  );
};
