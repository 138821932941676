import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { positionTypes } from "./positionTypes";
export const positionStartAddNew = (position) => {
    return async( dispatch, getState) => {
        //const { id } = getState().auth;
        try {
            const resp = await fetchConToken('positions/add', position, 'POST');
            const body = await resp.json();
            if(body.position === 'add'){
                position.id = body.id;
                dispatch(positionAddNew(position));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const positionAddNew = (position) => ({
    type: positionTypes.positionAddNew,
    payload: position
});

export const positionSetActive = (position) => ({
    type: positionTypes.positionSetActive,
    payload: position
});

export const positionClearActivePosition = () => ({
    type: positionTypes.positionClearActivePosition,
});

export const positionStartUpdate = ( position ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`positions/update/${ position.id }`, position, 'PUT' );
            const body = await resp.json();

            if ( body.positions !== 'null' ) {
                dispatch( positionUpdated( position ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const positionUpdated = (position) => ({
    type: positionTypes.positionUpdated,
    payload: position
});

export const positionDeleted = (position) => ({ type: positionTypes.positionDeleted });

export const positionStartLoading = () => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`positions/${ configuration.year }`);
                const body = await resp.json();
                const positions = body.positions;
                dispatch(positionLoaded(positions));
            } catch (error) {
            console.error(error);
        }
    }
}

const positionLoaded = (positions) => ({
    type: positionTypes.positionLoaded,
    payload: positions,
})

export const OpenMPosition = () => ({ type: positionTypes.OpenMPosition });
export const CloseMPosition = () => ({ type: positionTypes.CloseMPosition });
