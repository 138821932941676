import { competencieTypes } from "./competencieTypes"


const initialState = {
    competencies: [],
    modalOpenG: false,
    activeCompetencie: null
}

export const competencieReducer = (state = initialState, action) => {
    switch (action.type) {
        case competencieTypes.competencieSetActive:
            return{
                ...state,
                activeCompetencie: action.payload,
            }
        case competencieTypes.competencieAddNew:
            return{
                ...state,
                competencies:[
                    ...state.competencies,
                    action.payload
                ] 
            }
        case competencieTypes.competencieClearActiveCompetencie:
            return{
                ...state,
                activeCompetencie: null
            }
        case competencieTypes.competencieUpdated:
            return{
                ...state,
                competencies: state.competencies.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case competencieTypes.competencieDeleted:
            return{
                ...state,
                competencies: state.competencies.filter(
                    b => (b.id !== state.activeCompetencie.id)
                ),
                activeCompetencie: null
            }
        case competencieTypes.competencieLoaded:
            return{
                ...state,
                competencies: action.payload,
            }
        case competencieTypes.OpenMCompetencie:
            return {
                ...state,
                modalOpenG: true
            }
        case competencieTypes.CloseMCompetencie:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
