import { objectiveTypes } from "./objectiveTypes";

const initialState = {
    modalOpen: false,
    active: null,
    typeobjectives: [],
    objectives: [],
    loadingObjectives: true,
}

export const objectiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case objectiveTypes.OpenMObjective:
            return {
                ...state,
                modalOpen: true
            };
        case objectiveTypes.CloseMObjective:
            return {
                ...state,
                modalOpen: false
            };
        case objectiveTypes.objectives:
            return {
                ...state,
                objectives: action.payload,
                loadingObjectives: false
            }
        case objectiveTypes.addObjectives:
            return {
                ...state,
                objectives: [
                    ...state.objectives,
                    action.payload
                ]
            }
        case objectiveTypes.editObjectives:
            return {
                ...state,
                objectives: state.objectives.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case objectiveTypes.activeObjective:
            return {
                ...state,
                active: action.payload
            }
        case objectiveTypes.clearObjective:
            return {
                ...state,
                active: null
            }
        case objectiveTypes.typesObjective:
            return {
                ...state,
                typeobjectives: action.payload
            }
        default:
            return state;
    }
};
