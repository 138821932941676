import React from "react";
import { useSelector } from "react-redux";

export const NameUser = () => {
  const { name, type } = useSelector((state) => state.auth);
  let usertype = "";
  if (type === "administrator") {
    usertype = "Administrador";
  } else if (type === "evaluator") {
    usertype = "Evaluador";
  } else if (type === "collaborator") {
    usertype = "Evaluado";
  }
  return (
    <div className="container-fluid">
      <span className="text-dark text-justify">
        Bienvenido: <strong>{name}</strong>, ingresaste como{" "}
        <strong>{usertype}</strong>.
      </span>
    </div>
  );
};
