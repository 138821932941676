import { levelTypes } from "./levelTypes"


const initialState = {
    levels: [],
    modalOpenG: false,
    activeLevel: null
}

export const levelReducer = (state = initialState, action) => {
    switch (action.type) {
        case levelTypes.levelSetActive:
            return{
                ...state,
                activeLevel: action.payload,
            }
        case levelTypes.levelAddNew:
            return{
                ...state,
                levels:[
                    ...state.levels,
                    action.payload
                ] 
            }
        case levelTypes.levelClearActiveLevel:
            return{
                ...state,
                activeLevel: null
            }
        case levelTypes.levelUpdated:
            return{
                ...state,
                levels: state.levels.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case levelTypes.levelDeleted:
            return{
                ...state,
                levels: state.levels.filter(
                    b => (b.id !== state.activeLevel.id)
                ),
                activeLevel: null
            }
        case levelTypes.levelLoaded:
            return{
                ...state,
                levels: action.payload,
            }
        case levelTypes.OpenMLevel:
            return {
                ...state,
                modalOpenG: true
            }
        case levelTypes.CloseMLevel:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
