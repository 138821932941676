import React, { useEffect, useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { configuration } from '../../types/conf';
import { MainButton } from '../buttons/main/MainButton';
import { clearCommitments } from '../preview/previewActions';
import { Review } from '../review/Review';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { agreeStartLoading, clearAgreements, closeMagreements, startAgreementOption } from './agreementsActions';

const customStyles = {
    content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: '0%',
        Transform: 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initial = {
    name: ''
}

export const AgreementModal = () => {
    const { modalOpen, collaborator } = useSelector(state => state.agreements);
    const [formValues, setFormValues] = useState(initial);
    const { commitments } = useSelector(state => state.preview);
    const router = useHistory();
    const dispatch = useDispatch();
    let percent = 0;
    useEffect(() => {
            setFormValues(initial);
    }, [setFormValues]);
    if(commitments !== []){
        var idGoal = 0;
        var printGoal = true;
        const totalc = commitments.length - 1 ;
        commitments.map((c, index) => {
          printGoal = (idGoal === c.idGoal) ? false : true;
          idGoal = c.idGoal;
          percent += (printGoal) ? parseInt(c.percent) : 0;
          percent += (totalc === index) ? parseInt(c.percent) : 0;
        });
    }
    const closeModal = () => {
    dispatch(closeMagreements());
    dispatch(clearAgreements());
    dispatch(clearCommitments());
    setFormValues(initial);
    }
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    let updState = {
        state: 0,
    };
    const handleAgree = (e) =>{
        e.preventDefault();
        updState.collaborator = collaborator.id;
        updState.state = 'acepted';
        updState.description = formValues.name;
        dispatch(startAgreementOption(updState));
        closeModal();
    }
    const handleDissagree = (e) =>{
        e.preventDefault();
        updState.collaborator = collaborator.id;
        updState.state = 'rejected';
        updState.description = formValues.name;
        dispatch(startAgreementOption(updState));
        closeModal();
    }
    if(collaborator === null){
        return <></>;
    }
    return (
        < Modal
        isOpen = { modalOpen }
        onRequestClose = { closeModal }
        closeTimeoutMS = { 200}
        style = { customStyles }
        className = 'modal'
        overlayClassName = 'modal-fondo'
    >
        <Subtitle value={`Concertación ${collaborator.name}`} close={true} fclose={closeModal} />

        <form 
            className="container"
        >  
        <div className='overflow-auto' style={{ height: "400px" }}>
            {
                (commitments !== [])
                    ? <Review commitments={commitments} />
                    : <></>
            }
        </div>
        <div className="form-group">
                <label>Observaciones:</label>
                <textarea
                    rows="3"
                    className= 'form-control'
                    placeholder="Por favor escriba sus comentarios."
                    name="name"
                    autoComplete="off"
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted bg-light">Tienes alguna retroalimentaci&oacute;n para tu colaborador?</small>
            </div>
            <br />
            <div className='row'>
                    <div className='col'>
                        <MainButton
                        nameButton="sGeneral"
                        idButton="sGeneral"
                        handleClick={handleAgree}
                        valueButton="  Aceptar"
                        icon="far fa-thumbs-up"
                        back={configuration.greenColor}
                        />
                    </div>
                    <div className='col'>
                        <MainButton
                        nameButton="nGeneral"
                        idButton="nGeneral"
                        handleClick={handleDissagree}
                        valueButton=" Rechazar"
                        icon="far fa-thumbs-down"
                        back = "red"
                        text = "white"
                        />
                    </div>
                </div>
        </form>
     </Modal>   
    )
}
