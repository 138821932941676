import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { teamTypes } from "./teamTypes";
export const teamStartAddNew = (team) => {
    return async( dispatch, getState) => {
        //const { id } = getState().auth;
        try {
            const resp = await fetchConToken('teams/add', team, 'POST');
            const body = await resp.json();
            if(body.team === 'add'){
                team.id = body.id;
                dispatch(teamAddNew(team));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const teamAddNew = (team) => ({
    type: teamTypes.teamAddNew,
    payload: team
});

export const teamSetActive = (team) => ({
    type: teamTypes.teamSetActive,
    payload: team
});

export const teamClearActiveTeam = () => ({
    type: teamTypes.teamClearActiveTeam,
});

export const teamStartUpdate = ( team ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`teams/update/${ team.id }`, team, 'PUT' );
            const body = await resp.json();

            if ( body.teams !== 'null' ) {
                dispatch( teamUpdated( team ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const teamUpdated = (team) => ({
    type: teamTypes.teamUpdated,
    payload: team
});

export const teamDeleted = (team) => ({ type: teamTypes.teamDeleted });

export const teamStartLoading = () => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`teams/${ configuration.year }`);
                const body = await resp.json();
                const teams = body.teams;
                dispatch(teamLoaded(teams));
            } catch (error) {
            console.error(error);
        }
    }
}

const teamLoaded = (teams) => ({
    type: teamTypes.teamLoaded,
    payload: teams,
})

export const OpenMTeam = () => ({ type: teamTypes.OpenMTeam });
export const CloseMTeam = () => ({ type: teamTypes.CloseMTeam });
