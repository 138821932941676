import React from 'react'
import { useDispatch } from 'react-redux';
import './add.css';
export const AddButton = ({open, modal=true}) => {
    const dispatch = useDispatch();
    const handleClickNewB = () => {
        dispatch(open);
    }
    return (
        <button
            className="btn btn-primary fab"
            onClick={ modal === true ? handleClickNewB : open }
        >
            <i className="fas fa-plus"></i>
        </button>
    )
}
