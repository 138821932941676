import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startLogin } from '../../actions/auth';
import { useForm } from '../../hooks/useForm';
import {configuration} from '../../types/conf';
import { BackButton } from '../buttons/back/BackButton';

export const LoginScreen = () => {
    const { active } = useSelector(state => state.typeuser);
    const dispatch = useDispatch();
    const [formLoginValues, handleLoginInputChange] = useForm();
        const { lEmail, lPassword} = formLoginValues;
    let usertype = '';
    if(active === 'administrator'){
        usertype = 'Administrador';
    } else if(active === 'evaluator'){
        usertype = 'Evaluador';
    } else {
        usertype = 'Evaluado';
    }
    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(startLogin(lEmail, lPassword));

    }
    return (
        <div>
        <div className="container">
            <div className="row">
                <div className="coi-xs-12 col-s-2 col-md-3 col-lg-4">

                </div>
                <div className="coi-xs-12 col-s-8 col-md-6 col-lg-4">
                    <div className="card mt-3">
                        <div className="card-header" style={{backgroundColor: configuration.navBarColor, color: configuration.textMainColor}}>
                            <div className="row">
                                <div className="col-6">
                                    <img alt="logo RTVC" className="img-fluid" src="./assets/logo.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="card-body backgroundCard text-dark" >
                            <h3 style={{color: configuration.mainColor}}>{usertype}</h3>
                            <h5 className="card-title">Bienvenido al sistema de evaluaciones de RTVC</h5>
                            <form>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        name="lEmail"
                                        type="email"
                                        className="form-control border-0 border-bottom"
                                        id="email"
                                        aria-describedby="emailHelp"
                                        onChange={handleLoginInputChange}
                                        placeholder="Digite su correo institucional"/>
                                    <small id="emailHelp" className="form-text text-muted"></small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="lpassword">Password</label>
                                    <input
                                        name="lPassword"
                                        type="password"
                                        onChange={handleLoginInputChange}
                                        className="form-control border-0 border-bottom"
                                        id="password"
                                        aria-describedby="passwordHelp"
                                        placeholder="Digite su contraseña"/>
                                    <small id="passwordHelp" className="form-text text-muted text-justify\"><p style={{fontSize: '10px'}}>Si ingresas por primera vez digitar email institucional, la contraseña asignada y dar clic en Ingresar</p></small>
                                </div>
                                <div className="d-grid gap-2 justify-content d-lg-block">
                                    <button
                                        className="btn"
                                        name="enter"
                                        onClick={handleLogin}
                                        style={{backgroundColor: configuration.mainColor, color: configuration.textMainColor}}>Ingresar</button>
                                </div>
                            </form>
                            <div>
                        </div>
                    </div>
                </div>
                <div className="coi-xs-12 col-s-2 col-md-3 col-lg-4">

                </div>
            </div>
        </div>
    </div>
    <BackButton path="/"/>
    </div>
    )
}
