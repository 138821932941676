import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles.css';
import { configuration } from '../../types/conf';
import { MainButton } from '../buttons/main/MainButton';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { CloseMGrade, gradeClearActiveGrade, gradeStartAddNew, gradeStartUpdate } from './gradeActions';
const customStyles={
    content: {
        top        : 'auto',
        left       : 'auto',
        right      : 'auto',
        bottom     : 'auto',
        marginRight: '0%',
        Transform  : 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initGrade = {
    name: '',
    year: configuration.year,
    active: 1,
}
export const GradeModal = () => {
    const { activeGrade, modalOpenG }  = useSelector(state => state.grade);
    const [formValues, setFormValues] = useState(initGrade);
    const [titleValid, settitleValid] = useState(true);
    const dispatch = useDispatch();
    const {name, active, year } = formValues;
    useEffect(() => {
        if(activeGrade) {
            setFormValues(activeGrade);
        }else {
            setFormValues(initGrade);
        }
    }, [activeGrade, setFormValues]);
    const closeModalG = () => {
        dispatch( CloseMGrade());
        dispatch(gradeClearActiveGrade());
        setFormValues(initGrade);
    }
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) =>{
        e.preventDefault();
       
        if(name.trim().length < 1 ){
            settitleValid(false);
            return;
        }
        if( activeGrade) {
            dispatch(gradeStartUpdate(formValues));
        } else {
            dispatch(gradeStartAddNew(formValues));
        }
        settitleValid(true);
        closeModalG();
    }
    return (
        <Modal
            isOpen={modalOpenG}
            onRequestClose={closeModalG}
            style={customStyles}
            closeTimeoutMS={200}
            className='modal'
            overlayClassName='modal-fondo'
        >
            <Subtitle value={ (activeGrade) ? 'Editar Grado' : 'Crear Grado'} close={true} fclose={closeModalG}/>
            <form 
            className="container"
            onSubmit={handleSubmitForm}
            >
            <div className="form-group">
                <label>Nombre</label>
                <input 
                    type="text" 
                    className={`form-control ${!titleValid && 'is-invalid'}`}
                    placeholder="Nombre del grado"
                    name="name"
                    autoComplete="off"
                    value={name}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted">Nombre del Grado</small>
            </div>

            <div className="form-group">
                <label>Activo</label>
                <select 
                    className="form-select" 
                    aria-label="Active"
                    name="active"
                    value={active}
                    onChange={handleInputChange}
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Esta activo?</small>
            </div>
            <div className="form-group">
                <label>Año</label>
                <select 
                    className="form-select" 
                    aria-label="Year"
                    name="year"
                    value={year}
                    onChange={handleInputChange}
                    >
                        <option value={configuration.year}>{configuration.year}</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Año del grado</small>
            </div>
            <br />
            <MainButton
                nameButton="sGrade"
                idButton="sGrade"
                valueButton=" Guardar"
                handleClick={handleSubmitForm}
                icon="far fa-save"
            />

            </form>
        </Modal>
    )
}