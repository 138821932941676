export const evaluatorTypes = {
    evaluatorAddNew: '[evaluator] Add new Evaluator',
    evaluatorLoaded: '[evaluator] Evaluators laoded',
    colaboratorLoaded: '[evaluator] Colaborators loaded',
    evaluatorSetActive: '[evaluator] Set Active Evaluator',
    collaboratorSetActive: '[evaluator] Set Active Collaborator',
    evaluatorClearActiveEvaluator: '[evaluator] Clear Active Evaluator',
    evaluatorUpdated: '[evaluator] Evaluator Update',
    evaluatorDeleted: '[evaluator] Deleted Evaluator',
    checkColaborators: '[evaluator] clear checkColaborators',
    colaboratorClear: '[evaluator] clear colaborator'
}
