import Swal from "sweetalert2";
import { startLogout } from "../../actions/auth";
import { fetchConToken } from "../../helpers/fetch";
import { types } from "../../types/types";
import { userTypes } from "./userTypes";

export const OpenMUser = () => ({ type: userTypes.OpenMUser });
export const CloseMUser = () => ({ type: userTypes.CloseMUser });
export const setUserActive = (user) => {
    return async(dispatch) => {
        try {
            const resp = await fetchConToken(`user/email`,user,'POST');
            const body = await resp.json();
            const users = body.users;
            if(users !== "null"){
                const resp2 = await fetchConToken(`user/type/${users[0].id}`);
                const body2 = await resp2.json();
                const types = body2.types;
                if(types !== 'null'){
                    dispatch(userSetTypes(types));
                }
                    
                dispatch(userSetActive(users[0]));
            }     
        } catch (error) {
            console.error(error);
        }
    }
}
const userSetActive = (user) => ({
    type: userTypes.userSetActive,
    payload: user
});

const userSetTypes = (types) => ({
    type: userTypes.userSetTypes,
    payload: types
});

export const userStartAdd = (user) => {
    return async (dispatch, getState) => {
        const { activeTeam } = getState().team;
        user.email = activeTeam.email;
        user.name = activeTeam.name;
        try {
            const resp = await fetchConToken(`users/add`, user, 'POST');
            const body = await resp.json();
            if(body.user === 'add'){
                user.id = body.id;
                dispatch(userSetActive(user));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export const userUpdate = (user) => {
    return async (dispatch) => {
        try {
            const resp = await fetchConToken(`users/edit`, user, 'POST');
            const body = await resp.json();
            if(body.user === 'edit'){
                dispatch(userSetPhone(user.phone));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Datos actualizados con exito.',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            if(body.user === 'password'){
                dispatch(startLogout());
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const userSetPhone = (phone) => ({
    type: types.userSetPhone,
    payload: phone
});

export const userStartType = (type) => {
    return async(dispatch)=> {
        const resp = await fetchConToken(`user/type`,type,'POST');
        const body = await resp.json();
        if(body.typeuser === 'add'){
            const typeuser = type.type;
            dispatch(userSetTypes({type: typeuser}));
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'El tipo de usuario ha sido guardado',
                showConfirmButton: false,
                timer: 1500
              })
        }
    }
}

export const userClearActiveUser = () => ({
    type: userTypes.userClearActive,
});