import { agreementsType } from "./agreementsTypes";

const initAgree = {
    countGoals: 0,
    modalOpen: false,
    checking: true,
    agreeList: [],
    collaborator: null
}
export const agreementsReducer = (state = initAgree, action) => {
    switch (action.type) {
        case agreementsType.setCountGoals:
            return  ({
                ...state,
            }) 
        case agreementsType.openMagreements:
            return {
                ...state,
                modalOpen: true
            }
        case agreementsType.closeMagreements:
            return {
                ...state,
                modalOpen: false
            }
        case agreementsType.listTeam:
            return{
                ...state,
                agreeList: action.payload,
                checking: false
            }
        case agreementsType.collaborator:
            return{
                ...state,
                collaborator: action.payload
            }
        case agreementsType.clearCollaborator:
            return{
                ...state,
                collaborator: null
            }
        default:
            return state;
    }
}