import React, { useState, useEffect } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { MainButton } from '../buttons/main/MainButton';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { CloseMObjective, objectiveClearActive, objectiveStartAdd, objectiveStartUpdate } from './objectiveActions';
const customStyles = {
    content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: '0%',
        Transform: 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initial = {
    name: '',
    typeobjective: 1,
    activo: 1,
}
export const ObjectiveModal = () => {
    const { modalOpen, active, typeobjectives } = useSelector(state => state.objectives);
    const [titleValid, settitleValid] = useState(true);
    const [formValues, setFormValues] = useState(initial);
    const dispatch = useDispatch();
    const { name, typeobjective, activo } = formValues;
    const typeobj = (typeobjectives.length === 0)
        ? [{ "id": "1", "name": "Objetivo Personal" },
            { "id": "2", "name": "Objetivo Operativo" },
            { "id": "3", "name": "Objetivo Táctico" },
            { "id": "4", "name": "Objetivo Estratégico" }]
        : typeobjectives;
useEffect(() => {
    if (active) {
        setFormValues(active);
    } else {
        setFormValues(initial);
    }
}, [active, setFormValues]);
const closeModal = () => {
    dispatch(CloseMObjective());
    dispatch(objectiveClearActive());
    setFormValues(initial);
}
const handleInputChange = ({ target }) => {
    setFormValues({
        ...formValues,
        [target.name]: target.value
    });
}
const handleSubmitForm = (e) => {
    e.preventDefault();

    if (name.trim().length < 1) {
        settitleValid(false);
        return;
    }
    if (active) {
        dispatch(objectiveStartUpdate(formValues));
    } else {
        dispatch(objectiveStartAdd(formValues));
    }
    settitleValid(true);
    closeModal();
}
return (
    <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={200}
        style={customStyles}
        className='modal'
        overlayClassName='modal-fondo'
    >
        <Subtitle value={(active) ? 'Editar Objectivo' : 'Crear Objetivo'} close={true} fclose={closeModal} />
        <form
            className="container"
            onSubmit={handleSubmitForm}
        >
            <div className="form-group">
                <label>Objetivo</label>
                <textarea
                    rows="3"
                    className={`form-control ${!titleValid && 'is-invalid'}`}
                    placeholder="Compromiso Laboral"
                    name="name"
                    autoComplete="off"
                    value={name}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted bg-light">Corresponde a los productos o servicios esperados para el respectivo periodo de evaluación en el marco de las funciones del servidor.</small>
            </div>
            <div className="form-group">
                <label>Tipo de Objetivo</label>
                <select
                    className="form-select"
                    aria-label="Objective"
                    name="typeobjective"
                    value={typeobjective}
                    onChange={handleInputChange}
                >
                    {
                        (typeobj !== 'null')
                            ? typeobj.map((objective, index) => {
                                return (
                                    <option key={index} value={objective.id}>{objective.name}</option>
                                );
                            })
                            : <></>
                    }
                </select>
            </div>
            <div className="form-group">
                <label>Activo</label>
                <select
                    className="form-select"
                    aria-label="Activo"
                    name="activo"
                    value={activo}
                    onChange={handleInputChange}
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Esta activo?</small>
            </div>
            <br />
            <MainButton
                nameButton="sTypeobjective"
                idButton="sTypeobjective"
                valueButton=" Guardar"
                handleClick={handleSubmitForm}
                icon="far fa-save"
            />
        </form>
    </Modal>
);
};
