import React from 'react'
import Modal from 'react-modal/lib/components/Modal'
import { useDispatch, useSelector } from 'react-redux';
import { Subtitle } from '../../ui/subtitle/Subtitle';
import './help.css';
import { CloseMHelp } from './helpActions';
const customStyles = {
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    Transform: 'translate(-50%, -50%)',

}
}
Modal.setAppElement('#root');
export const HelpModal = ({ type, path }) => {
  const { openMHelp } = useSelector(state => state.help);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(CloseMHelp());
  }
  const styleModal = (type === 'video') ? 'video-responsive' : '';
  return (
    <Modal
      isOpen={openMHelp}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      style={customStyles}
      className='modal'
    >
       <Subtitle value={ 'Ayuda'} close={true} fclose={closeModal}/>
      <pre>
        <div className={styleModal} >
          {
            type === 'video'
              ? <iframe src={path} title="Ayuda" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
              : <object data={path} type="application/pdf" style={{ width: "100%", height: "80vh" }} >
                <p>Clic para ver el plan de Capacitaci&oacute;n <a target={'_blank'} rel="noreferrer" href="https://s3.amazonaws.com/rtvc-assets-qa-sistemasenalcolombia.gov.co/archivos/plan_estrategico_talento_humano_2022_h.pdf"> Ver PDF!</a></p>
              </object>
          }


        </div>
      </pre >
    </Modal >
  )
}
