import { commonTypes } from "./commonTypes"


const initialState = {
    commons: [],
    modalOpenG: false,
    activeCommon: null
}

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case commonTypes.commonSetActive:
            return{
                ...state,
                activeCommon: action.payload,
            }
        case commonTypes.commonAddNew:
            return{
                ...state,
                commons:[
                    ...state.commons,
                    action.payload
                ] 
            }
        case commonTypes.commonClearActiveCommon:
            return{
                ...state,
                activeCommon: null
            }
        case commonTypes.commonUpdated:
            return{
                ...state,
                commons: state.commons.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case commonTypes.commonDeleted:
            return{
                ...state,
                commons: state.commons.filter(
                    b => (b.id !== state.activeCommon.id)
                ),
                activeCommon: null
            }
        case commonTypes.commonLoaded:
            return{
                ...state,
                commons: action.payload,
            }
        case commonTypes.OpenMCommon:
            return {
                ...state,
                modalOpenG: true
            }
        case commonTypes.CloseMCommon:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
