import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { commonTypes } from "./commonTypes";
export const commonStartAddNew = (common) => {
    return async( dispatch) => {
        try {
            const resp = await fetchConToken('commons/add', common, 'POST');
            const body = await resp.json();
            if(body.commons === 'add'){
                common.id = body.id;
                dispatch(commonAddNew(common));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const commonAddNew = (common) => ({
    type: commonTypes.commonAddNew,
    payload: common
});

export const commonSetActive = (common) => ({
    type: commonTypes.commonSetActive,
    payload: common
});

export const commonClearActiveCommon = () => ({
    type: commonTypes.commonClearActiveCommon,
});

export const commonStartUpdate = ( common ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`commons/update/${ common.id }`, common, 'PUT' );
            const body = await resp.json();

            if ( body.commons !== 'null' ) {
                dispatch( commonUpdated( common ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const commonUpdated = (common) => ({
    type: commonTypes.commonUpdated,
    payload: common
});

export const commonDeleted = (common) => ({ type: commonTypes.commonDeleted });

export const commonStartLoading = (position) => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`commons/${ position }`);
                const body = await resp.json();
                const commons = body.commons;
                dispatch(commonLoaded(commons));
            } catch (error) {
            console.error(error);
        }
    }
}

const commonLoaded = (commons) => ({
    type: commonTypes.commonLoaded,
    payload: commons,
})

export const OpenMCommon = () => ({ type: commonTypes.OpenMCommon });
export const CloseMCommon = () => ({ type: commonTypes.CloseMCommon });
