import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";

import { Subtitle } from "../ui/subtitle/Subtitle";
import { AddButton } from "../buttons/add/AddButton";
import { Lists } from "../ui/lists/Lists";
import { commonStartLoading, OpenMCommon } from "./commonActions";
import { CommonModal } from "./CommonModal";
import { NavBar } from "../navbar/NavBar";

export const CommonScreen = () => {
  const { activePosition}  = useSelector(state => state.position);
  const [dataPosition] = useState(activePosition);
  const {id, name} = dataPosition;
  const dispatch = useDispatch();
  const {commons} = useSelector(state => state.common);
  useEffect(() => {
      dispatch(commonStartLoading(id));
  }, [dispatch]);
  return (
    <div>
    <NavBar/>
      <div className="container">
        <Subtitle value={name}/>
        <Subtitle value="Competencias Comunes" />
        <Lists rows={commons} edit={true} idList='commonList' openModal={OpenMCommon()}/>
      </div>
      <BackButton path="/competencies" />
      <AddButton open={OpenMCommon()} />
      <CommonModal position={id}/>
    </div>
  );
};
