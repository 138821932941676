import React, { useEffect } from 'react'
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Subtitle } from "../ui/subtitle/Subtitle";
import { MainButton } from '../buttons/main/MainButton'
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { colaboratorStartLoading, evaluatorSetActive, evaluatorStartLoading } from './evaluatorActions';
import Swal from 'sweetalert2';
import { Loading } from '../ui/loading/Loading';
import { teamStartLoading } from '../teams/teamActions';

export const EvaluatorScreen = () => {
    const router = useHistory();
    const {teams} = useSelector(state => state.team);
    const {checking, activeEvaluator, checkColaborators} = useSelector(state => state.evaluator);
    const dispatch = useDispatch();
    const handleChange = (e) => {
        let index = e.target.selectedIndex;
        dispatch(evaluatorSetActive({
            id: e.target.options[index].value,
            name: e.target.options[index].text
        })); 
    }
    const handleClick = ({target}) =>{
        const evaluator = activeEvaluator;
        if(!!evaluator && evaluator.id !== 0){
            dispatch(colaboratorStartLoading(evaluator.id));
        } else {
            Swal.fire('Error', 'Por favor seleccione el evaluador', 'error');
        }
    }
    if(!checkColaborators ){
        router.push('addEval');
    }

    useEffect(() => {
        dispatch(teamStartLoading());
        dispatch(evaluatorStartLoading());
    }, [dispatch]);
    if(checking){
        return <Loading/>;
    }
    return (
        <div>
            <NavBar />
            <div className="container">
                <Subtitle value="Evaluadores" />
                <div className="container row ">
                    <div className="form-group">
                        <label>Por favor seleccione el evaluador:</label>
                        <select
                            className="form-select"
                            aria-label="Active"
                            name="evaluator"
                            id="evaluator"
                            onChange={handleChange}
                            >
                                <option key={0} value={0}>Seleccione...</option>
                            {
                                teams.map((person, index) => {
                                    return(
                                    <option key={index} value={person.id}>{person.name + ' - ' + person.pname}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className='row' style={{marginTop: '20px'}}>
                        
                            {
                                
                            /*
                            <div className='col' >
                            <MainButton
                                idButton='editEval'
                                nameButton='editEval'
                                valueButton='Modificar Evaluador'
                                disabled={disabled}
                                handleClick={handleClick}
                            ></MainButton> 
                            </div> */ }
                        
                        <div className='col'>
                            <MainButton
                                idButton='addEval'
                                nameButton='addEval'
                                valueButton='Asignar Evaluados'
                                handleClick={handleClick}
                            ></MainButton>
                        </div>
                    </div>
                </div>
            </div>
            <BackButton path="/main" />
        </div>
    )
}
