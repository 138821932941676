import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton } from '../buttons/back/BackButton';
import { NavBar } from '../navbar/NavBar';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { Loading } from '../ui/loading/Loading';
import { InfoButton } from '../buttons/info/InfoButton';
import { useHistory } from 'react-router-dom';
import { Lists } from '../ui/lists/Lists';
import { MainButton } from '../buttons/main/MainButton';
import { objectiveStartLoading } from '../objectives/objectiveActions';
import { goalStartAdd, goalStartUpdate } from './goalsActions';

export const GoalScreen = () => {
    const {goalOption, active} = useSelector(state => state.commitment);
    const {collaborator} = useSelector(state => state.general);
    const {loadingObjectives, objectives} = useSelector(state => state.objectives);
    const {activeGoal} = useSelector(state => state.goals);
    const collab = collaborator[0];
    const router = useHistory();
    const initGoal = {
        objective: 0,
        name: '',
        percent: 0,
        activo: 1,
    }
    const [formValues, setFormValues] = useState(initGoal);
    const initPercent = (goalOption === 'edit') ? parseInt(activeGoal.percent) : initGoal.percent;
    const [percentValue, setpercentValue] = useState(initPercent);
    const [titleValid, settitleValid] = useState(true);
    useEffect(() => {
        if(goalOption === 'edit') {
            setFormValues({
                objective: activeGoal.objective,
                name: activeGoal.name,
                id: activeGoal.id,
                percent: parseInt(activeGoal.percent),
                activo: activeGoal.activo,
            });
        }else {
            setFormValues(initGoal);
        }
    }, [activeGoal, setFormValues]);
    const {name, objective, percent, activo } = formValues;


    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(objectiveStartLoading(collab.id));
    }, [dispatch]);
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const onChangePercent = (e) => {
        setpercentValue(e.target.value);
        const freePercent = parseInt(active[0].percent) + parseInt(initPercent) - parseInt(e.target.value);
        setFormValues({
            ...formValues,
            percent: e.target.value,
            commitment: freePercent
        });
    }
    const handleSubmitForm = (e) =>{
        e.preventDefault();
        if(name.trim().length < 1 ){
            settitleValid(false);
            return;
        }
        if(goalOption === 'edit'){
            dispatch(goalStartUpdate(formValues));
        } else {
           dispatch(goalStartAdd(formValues));
        }
        setFormValues(initGoal);
        //(goalOption === 'open') ? router.push('/activities') : router.push('/commitments');
        router.push('/commitments');
    }
    const handleActivities = (e) => {
        e.preventDefault();
        router.push('/activities');
    }
    const maxPercent=parseInt(active[0].percent)+parseInt(initPercent);

    if(loadingObjectives){
        return(
            <Loading/>
        )
    }
  return (<div>
      <NavBar/>
      <div className='container'>
        <Subtitle value={goalOption === 'open' ? 'Crear Meta' : 'Editar Meta'}/>
        <form
            className="container bg-light"
            style={{opacity: '0.85'}}
            onSubmit={handleSubmitForm}
        >
             <div className="form-group">
                <label>Objetivo</label>
                <div className='row'>
                    <div className='col-10'>
                        <select
                        className="form-select"
                        aria-label="Objective"
                        name="objective"
                        value={objective}
                        onChange={handleInputChange}
                        >
                            <option key={0} value={0}>Seleccione...</option>
                                {
                                    (objectives !== 'null')
                                    ? objectives.map((objective, index) => {
                                            return(
                                            <option key={index} value={objective.id}>{objective.name}</option>
                                            );
                                        })
                                        : <></>
                                }
                        </select>
                    </div>
                    <div className='col-2'>
                        <InfoButton name='objectives' id='objectives' path='/objectives' />
                    </div>
                </div>

                <small id="objectiveHelp" className="form-text text-muted">Si no encuentra el objetivo dar clic en el boton ver</small>
            </div>
            <div className='form-group'>
                <label>Meta:</label>
                <textarea
                    rows={3}
                    className={`form-control`}
                    placeholder="Escribe tu meta a cumplir"
                    name="name"
                    autoComplete="off"
                    value={name}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted">Son los resultados esperados en el periodo de evaluación expresados en términos de magnitud, tiempo, cantidad y otros factores que el evaluador disponga como necesarios para establecer los avances o logros requeridos para su cumplimiento.</small>
            </div>
            <div className='form-group'>
                <label for="percent" className="form-label">Porcentaje (Máx. {maxPercent}%)</label>
                <div className='row'>
                    <div className='col-10'>
                        <input name='percent' className="form-range" type="range" min="0" max={maxPercent} step='1' defaultValue={percentValue} onChange={onChangePercent} />
                    </div>
                    <div className='col-2'><h3>{percentValue}%</h3></div>
                </div>
                <small id="percentHelp" className="form-text text-muted">Asignale un peso a tu objetivo.</small>

            </div>
            <div className="form-group">
                <label>Activo</label>
                <select
                    className="form-select"
                    aria-label="Activo"
                    name="activo"
                    value={activo}
                    onChange={handleInputChange}
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Esta activo?</small>
            </div>
            <br/>
            <MainButton
                valueButton={'Continuar'}
                nameButton="sGoal"
                idButton="sGoal"
                handleClick={handleSubmitForm}
            />
            <br />
            <br />
            {
                (goalOption === 'edit')
                ? <MainButton
                valueButton={'Actividades'}
                nameButton="eActivity"
                idButton="eActivity"
                handleClick={handleActivities}
                />
                : <></>
            }
            <br />
        </form>
      </div>
      <BackButton path="/commitments" />
  </div>);
};
