import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { competencieTypes } from "./competencieTypes";
export const competencieStartAddNew = (competencie) => {
    return async( dispatch, getState) => {
        //const { id } = getState().auth;
        try {
            const resp = await fetchConToken('competencies/add', competencie, 'POST');
            const body = await resp.json();
            if(body.competencie === 'add'){
                competencie.id = body.id;
                dispatch(competencieAddNew(competencie));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const competencieAddNew = (competencie) => ({
    type: competencieTypes.competencieAddNew,
    payload: competencie
});

export const competencieSetActive = (competencie) => ({
    type: competencieTypes.competencieSetActive,
    payload: competencie
});

export const competencieClearActiveCompetencie = () => ({
    type: competencieTypes.competencieClearActiveCompetencie,
});

export const competencieStartUpdate = ( competencie ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`competencies/update/${ competencie.id }`, competencie, 'PUT' );
            const body = await resp.json();

            if ( body.competencies !== 'null' ) {
                dispatch( competencieUpdated( competencie ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const competencieUpdated = (competencie) => ({
    type: competencieTypes.competencieUpdated,
    payload: competencie
});

export const competencieDeleted = (competencie) => ({ type: competencieTypes.competencieDeleted });

export const competencieStartLoading = () => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`competencies/${ configuration.year }`);
                const body = await resp.json();
                const competencies = body.competencies;
                dispatch(competencieLoaded(competencies));
            } catch (error) {
            console.error(error);
        }
    }
}

const competencieLoaded = (competencies) => ({
    type: competencieTypes.competencieLoaded,
    payload: competencies,
})

export const OpenMCompetencie = () => ({ type: competencieTypes.OpenMCompetencie });
export const CloseMCompetencie = () => ({ type: competencieTypes.CloseMCompetencie });
