import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Subtitle } from "../ui/subtitle/Subtitle";
import { AddButton } from "../buttons/add/AddButton";
import { Lists } from "../ui/lists/Lists";
import { rankStartLoading, OpenMRank } from "./rankActions";
import { RankModal } from "./RankModal";

export const RankScreen = () => {
  const { activePosition}  = useSelector(state => state.position);
  const [dataPosition] = useState(activePosition);
  const {id, name} = dataPosition;
  const dispatch = useDispatch();
  const {ranks} = useSelector(state => state.rank);
  useEffect(() => {
      dispatch(rankStartLoading(id));
  }, [dispatch]);
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value={name}/>
        <Subtitle value="Competencias x Nivel Jerarquico" />
        <Lists rows={ranks} edit={true} idList='rankList' openModal={OpenMRank()}/>
      </div>
      <BackButton path="/competencies" />
      <AddButton open={OpenMRank()} />
      <RankModal position={id}/>
    </div>
  );
};
