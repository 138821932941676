import { areaTypes } from "./areaTypes"


const initialState = {
    areas: [],
    modalOpenG: false,
    activeArea: null
}

export const areaReducer = (state = initialState, action) => {
    switch (action.type) {
        case areaTypes.areaSetActive:
            return{
                ...state,
                activeArea: action.payload,
            }
        case areaTypes.areaAddNew:
            return{
                ...state,
                areas:[
                    ...state.areas,
                    action.payload
                ] 
            }
        case areaTypes.areaClearActiveArea:
            return{
                ...state,
                activeArea: null
            }
        case areaTypes.areaUpdated:
            return{
                ...state,
                areas: state.areas.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case areaTypes.areaDeleted:
            return{
                ...state,
                areas: state.areas.filter(
                    b => (b.id !== state.activeArea.id)
                ),
                activeArea: null
            }
        case areaTypes.areaLoaded:
            return{
                ...state,
                areas: action.payload,
            }
        case areaTypes.OpenMArea:
            return {
                ...state,
                modalOpenG: true
            }
        case areaTypes.CloseMArea:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
