import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

export const InfoButton = ({ path, setActive }) => {
  const dispatch = useDispatch();
  const route = useHistory();
  const handleClickInfo = (e) => {
    e.preventDefault();
    if(setActive != undefined)
      dispatch(setActive);
    route.push(path);
    }
  return (
    <button
      className="btn btn-info"
      onClick={handleClickInfo}
      style={{ paddingBottom: "8px" }}
    >
      <i className="fas fa-eye"></i>
    </button>
  );
};
