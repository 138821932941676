import React from 'react'
import {configuration} from '../../../types/conf';

export const MainButton = ({nameButton, idButton, valueButton,handleClick, icon, text=configuration.textMainColor,  back = configuration.mainColor}) => {
    return (
        <button
            className="btn"
            name={nameButton}
            id={idButton}
            style={{ width: '100%', backgroundColor: back, color: text}}
            onClick={handleClick}>
                <i className={icon}></i>
                <span>{valueButton}</span>
        </button>
    )
}
