import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { startLogout } from "../../actions/auth";
import { NameUser } from "./NameUser";

export const NavBar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(startLogout());
  };
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    if (e.target.id === "personal") {
      history.push("/personal");
    }
    if (e.target.id === "dashboard") {
      history.push("/main");
    }
  };

  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="row p-2">
                <a className="col-9">
                    <img style={{maxWidth: '30%'}} alt="logo RTVC" className="img-fluid" src="../assets/logo.png"/>
                </a>
                <div className="text-end col-3">
                    <i
                        id="dashboard"
                        className="text-light fas fa-home"
                        style={{cursor: 'pointer'}}
                        onClick={handleClick}
                    />{"  "}
                    <i
                        id="personal"
                        className="text-light fas fa-user"
                        style={{cursor: 'pointer'}}
                        onClick={handleClick}
                    />{"  "}
                    <i
                        className="text-danger fas fa-sign-out-alt"
                        style={{cursor: 'pointer'}}
                        onClick={handleLogout}
                    />
                </div>
            </div>
        </nav>
        <NameUser/>
    </div>
  );
};
