import { generalTypes } from "./generalTypes";

const initialState = {
    collaborator: [],
    activeCollaborator: null,
    activeEvaluator: null,
    loading: true,
    step: [],
    steps: null,
    loadingSteps: true
}


export const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case generalTypes.collaboratorLoaded:
            return{
                ...state,
                collaborator: action.payload,
                loading: false,
            }
        case generalTypes.evaluatorLoaded:
            return{
                ...state,
                activeEvaluator: action.payload,
                loading: false,
            }
        case generalTypes.stepGeneral:
            return{
                ...state,
                steps: [action.payload],
                loadingSteps: false
            }
        case generalTypes.stepsLoaded:
            return{
                ...state,
                steps: action.payload,
                loadingSteps: false
            }
        case generalTypes.generalAddNew:
            return{
                ...state,
            }
        default:
            return state;
    }

};
