import { fetchConToken } from "../../helpers/fetch";
import { commitmenType } from "./commimenType";


export const setGoalOptions = (option) => ({
  type: commitmenType.goalAction,
  payload: option
});

export const commitmentStartLoading = (id)=>{
    return async(dispatch, getState) => {
        const {collaborator} = getState().general;
        try {
            const resp = await fetchConToken(`commitments/${collaborator[0].id}`);
            const body = await resp.json();
            const commitments = body.commitments;
            dispatch(commitmentsLoaded(commitments));
        } catch (error) {
            console.error(error);

        }
    }
}

const commitmentsLoaded = (commitments) => ({
    type: commitmenType.commitment,
    payload: commitments
});

export const goalStartLoading = (id) => {
    return async(dispatch) => {
        try {
            const resp = await fetchConToken(`goals/${id}`);
            const body = await resp.json();
            dispatch(goalsLoaded(body.goals));
        } catch (error) {
            console.error(error);
        }
    }
}

const goalsLoaded = (goals) => ({
    type: commitmenType.goals,
    payload: goals
});
