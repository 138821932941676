import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { activitesTypes } from "./activitiesTypes";

export const activitieStartAdd = (activity) => {
    return async(dispatch, getState) => {
        const { collaborator } = getState().general;
        const {activeGoal} = getState().goals;
        activity.collaborator = collaborator[0].id;
        activity.goal = activeGoal.id;
        try {
            const resp = await fetchConToken(`activities/add`,activity,'POST');
            const body = await resp.json();
            if(body.activities !== 'null'){
                activity.id = body.id;
                dispatch(activityAdd(activity));
                dispatch(activitySetActive(null));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const activityAdd = (activity) => ({
    type: activitesTypes.addActivity,
    payload: activity

});

export const activitieStartLoading = () => {
    return async(dispatch, getState) => {
        const {activeGoal} = getState().goals;
        try {
            const resp = await fetchConToken(`activities/goal/${activeGoal.id}`);
            const body = await resp.json();
            dispatch(activitiesLoaded(body.activities));
        } catch (error) {
            console.error(error);
        }
    }
}

const activitiesLoaded = (activities) => ({
    type: activitesTypes.activities,
    payload: activities
})

export const OpenMActivity = () => ({ type: activitesTypes.OpenMActivity });
export const CloseMActivity = () => ({ type: activitesTypes.CloseMActivity });
export const activityClearActive = () => ({ type: activitesTypes.clearActivity });

export const activitySetActive = (activity) => ({
    type: activitesTypes.setActivity,
    payload: activity
});

export const activitiesStartUpdate = (activity) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken(`activities/update/${activity.id}`, activity, 'PUT');
            const body = await resp.json();
            if(body.activities !== 'null'){
                dispatch(activityUpdated(activity));
            } else {
                Swal.fire('Error', body.error, 'error');
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const activityUpdated = (activity) => ({
    type: activitesTypes.editActivity,
    payload: activity
});