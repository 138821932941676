import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton } from '../buttons/back/BackButton';
import { MainButton } from '../buttons/main/MainButton';
import { NavBar } from '../navbar/NavBar';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { userUpdate } from '../users/userActions';

export const PersonalInfoScreen = () => {
    const [show, setShow] = useState(false);
    const { id, phone } = useSelector(state => state.auth);
    const activeUser = {id, phone, password:''}
    const [formValues, setFormValues] = useState(activeUser);
    const dispatch = useDispatch();
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) => {
        e.preventDefault();
        dispatch(userUpdate(formValues));
    }
    const switchShow = () => setShow(!show);
    return (
        <div>
            <NavBar/>
            <div className='container'>
                <Subtitle value={'Editar Perfil'}/>
                <form
                    className="container"
                    onSubmit={handleSubmitForm}
                >
                    <div className="form-group">
                        <label>Password</label>
                        <div className="input-group">
                            <input className='form-control' type={show ? 'text':'password'} name='password' onChange={handleInputChange} />
                            <button onClick={switchShow} className="btn btn-outline-secondary" type="button"><i className="fas fa-eye"></i></button>
                        </div>
                        <small id="passwordHelp" className="form-text text-muted bg-light">Digite la contrase&ntilde;a de acceso a la plataforma.</small>
                    </div>
                    <div className="form-group">
                        <label>Tel&eacute;fono</label>
                        <input className='form-control' type={'phone'} name='phone' value={formValues.phone} onChange={handleInputChange} />
                    </div>
                    <br />
                    <MainButton
                        nameButton="sTypeobjective"
                        idButton="sTypeobjective"
                        valueButton='Guardar'
                        handleClick={handleSubmitForm}
                        icon="far fa-save"
                    />
                </form>
            </div>
            <BackButton path="/main"/>
        </div>
    )
}
