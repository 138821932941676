import { trainingTypes } from "./trainingTypes";

const initialTraining = {
    add: false
}
export const trainingReducer = (state = initialTraining, action) => {
    switch (action.type) {
        case trainingTypes.addTraining:
            return {
                ...state,
                add: true
            }
        case trainingTypes.closeTraining:
            return {
                ...state,
                add: false
            }
        default:
            return state;
    }
};
