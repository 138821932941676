import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {configuration} from '../../types/conf';
import './profile.css';
import { UpdateTypeUser } from './profileActions';

export const ProfileScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const handleClick = ({target}) =>{
        dispatch(UpdateTypeUser({active: target.name}));
        history.push('/login');
    }
    return (
        <div className="container">
            <div className="row">
                <div className="coi-xs-12 col-s-2 col-md-3 col-lg-4">

                </div>
                <div className="coi-xs-12 col-s-8 col-md-6 col-lg-4">
                    <div className="card mt-3">
                        <div className="card-header" style={{backgroundColor: configuration.navBarColor, color: configuration.textMainColor}}>
                            <div className="row">
                                <div className="col-6">
                                    <img alt="logo RTVC" className="img-fluid" src="./assets/logo.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="card-body backgroundCard text-dark" >
                            <h5 className="card-title">Bienvenido al sistema de evaluaci&oacute;n de los Servidores P&uacute;blicos de RTVC </h5>
                            <p className="card-text">Vas a ingresar como:</p>
                            <div>
                            <div className="d-grid gap-2 col-10 mx-auto">
                                <button className="btn" name="collaborator" style={{backgroundColor: configuration.mainColor, color: configuration.textMainColor}} onClick={handleClick}>Evaluado</button>
                                <button className="btn" name="evaluator" style={{backgroundColor: configuration.mainColor, color: configuration.textMainColor}} onClick={handleClick}>Evaluador</button>
                                <button className="btn" name="administrator"  style={{backgroundColor: configuration.mainColor, color: configuration.textMainColor}} onClick={handleClick}>Administrador</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="coi-xs-12 col-s-2 col-md-3 col-lg-4">

                </div>
            </div>
        </div>
    )
}
