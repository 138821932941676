import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { levelTypes } from "./levelTypes";
export const levelStartAddNew = (level) => {
    return async( dispatch, getState) => {
        //const { id } = getState().auth;
        try {
            const resp = await fetchConToken('levels/add', level, 'POST');
            const body = await resp.json();
            if(body.level === 'add'){
                level.id = body.id;
                dispatch(levelAddNew(level));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const levelAddNew = (level) => ({
    type: levelTypes.levelAddNew,
    payload: level
});

export const levelSetActive = (level) => ({
    type: levelTypes.levelSetActive,
    payload: level
});

export const levelClearActiveLevel = () => ({
    type: levelTypes.levelClearActiveLevel,
});

export const levelStartUpdate = ( level ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`levels/update/${ level.id }`, level, 'PUT' );
            const body = await resp.json();

            if ( body.levels !== 'null' ) {
                dispatch( levelUpdated( level ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const levelUpdated = (level) => ({
    type: levelTypes.levelUpdated,
    payload: level
});

export const levelDeleted = (level) => ({ type: levelTypes.levelDeleted });

export const levelStartLoading = () => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`levels/${ configuration.year }`);
                const body = await resp.json();
                const levels = body.levels;
                dispatch(levelLoaded(levels));
            } catch (error) {
            console.error(error);
        }
    }
}

const levelLoaded = (levels) => ({
    type: levelTypes.levelLoaded,
    payload: levels,
})

export const OpenMLevel = () => ({ type: levelTypes.OpenMLevel });
export const CloseMLevel = () => ({ type: levelTypes.CloseMLevel });
