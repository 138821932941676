import React from 'react';
import { Rows } from '../row/Rows';

export const Lists = ({rows, edit, idList, openModal, info, infoPath, varios, action, modal=true, setVarios='none'}) => {
    if(rows !== 'null'){
       let iconVarios = '';
       let colorVarios = '';
       let contList = 0;
        return (
            <div id={idList} style={{height:'72vh', overflowY: 'scroll', overflowX: 'hidden'}} className="overflow-scroll">
                {
                    rows.map((row) =>{
                        if(varios === true) {
                            if(idList === 'colaboratorList'){
                                iconVarios = (row.evaluator !== '0') ? 'fas fa-user-minus' : 'fas fa-user-plus';
                                colorVarios = (row.evaluator !== '0') ? 'btn btn-danger' : 'btn btn-success';
                            } else if (idList === 'teamList'){
                                iconVarios = 'fas fa-user-cog';
                                colorVarios = 'btn btn-primary';
                            } else if (idList === 'colabEvalList'){
                                iconVarios = 'fas fa-user-check';
                                colorVarios = 'btn btn-success';
                            } else {
                                iconVarios = 'fas fa-cog';
                                colorVarios = 'btn btn-primary';
                            }
                        }
                        contList++;
                        if(row.name){
                            return(
                                <Rows
                                    name={row.name}
                                    index={contList}
                                    key={row.id}
                                    active={row.active}
                                    edit={edit}
                                    info={info}
                                    update={row}
                                    idList={idList}
                                    openModal={openModal}
                                    modal={modal}
                                    varios={iconVarios}
                                    colorVarios={colorVarios}
                                    action={action}
                                    infoPath={(infoPath === 'path') ? row.path : infoPath}
                                    setVarios={setVarios}
                                />
                            );
                        }
                    })
                }
            </div>
        );
    }else{
        return(
            <></>
        );
    }

}
