import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainButton } from '../buttons/main/MainButton';
import { CloseMTeam, teamClearActiveTeam } from '../teams/teamActions';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { userClearActiveUser, userStartAdd, userStartType } from './userActions';


const initial = {
    password: '',
    phone: '',
}
export const UserModal = () => {
    const { activeUser, types } = useSelector(state => state.users);
    const [formValues, setFormValues] = useState(initial);
    const [titleValid, settitleValid] = useState(true);
    const dispatch = useDispatch();
    const { password, phone } = formValues;
    let disabledEvaluator = '';
    let disabledCollaborator = '';
    if (types && activeUser) {
        types.map(user => {
            if (user.type === "evaluator"){
                disabledEvaluator = 'disabled';
            } 
            if(user.type === "collaborator"){
                disabledCollaborator =  'disabled';
            }
        });
    }
    useEffect(() => {
        if (activeUser) {
            setFormValues(activeUser);
        } else {
            setFormValues(initial);
        }
    }, [activeUser, setFormValues]);
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) => {
        e.preventDefault();

        if (activeUser) {
            // dispatch(usersStartUpdate(formValues));
        } else {
            if (password.trim().length < 1) {
                settitleValid(false);
                return;
            }
            dispatch(userStartAdd(formValues));
        }
        settitleValid(true);
        dispatch(CloseMTeam());
        dispatch(userClearActiveUser());
        dispatch(teamClearActiveTeam());
    }
    const handleTypeUser = (e) => {
        const typeuser = {
            user: activeUser.id,
            type: e.target.id
        }
        if(typeuser.type!==''){
            dispatch(userStartType(typeuser));
            dispatch(CloseMTeam());
            dispatch(userClearActiveUser());
            dispatch(teamClearActiveTeam());
        }
        
    }
    return (
        <>
            <Subtitle value={(activeUser) ? '  Editar Usuario' : '  Crear Usuario'} />
            <form
                className="container"
                onSubmit={handleSubmitForm}
            >
                <div className="form-group">
                    <label>Password</label>
                    <input className='form-control' type={'password'} name='password' onChange={handleInputChange} />
                    <small id="passwordHelp" className="form-text text-muted bg-light">Digite la contrase&ntilde;a de acceso a la plataforma.</small>
                </div>
                <div className="form-group">
                    <label>Tel&eacute;fono</label>
                    <input className='form-control' type={'phone'} name='phone' value={phone} onChange={handleInputChange} />
                </div>
                <br />
                <MainButton
                    nameButton="sTypeobjective"
                    idButton="sTypeobjective"
                    valueButton={(activeUser) ? '  Editar' : '  Crear'}
                    handleClick={handleSubmitForm}
                    icon="far fa-save"
                />
            </form>
            {
                (activeUser)
                    ? <div>
                        <h4>Tipo de Usuario:</h4>
                        <div className='row text-center'>
                            <div className="col">
                                <button onClick={handleTypeUser} data-toggle="tooltip" disabled={disabledEvaluator} data-placement="top" title="Evaluador" id='evaluator' className='btn btn-primary' style={{ paddingBottom: "8px" }}>
                                    <i className={'fas fa-user-edit'}></i>
                                </button>
                                <p>Evaluador</p>
                            </div>
                            <div className="col">
                                <button onClick={handleTypeUser} data-toggle="tooltip" disabled={disabledCollaborator} data-placement="top" title="Evaluado" id='collaborator' className='btn btn-primary' style={{ paddingBottom: "8px" }}>
                                    <i className={'fas fa-user-check'}></i>
                                </button>
                                <p>Evaluado</p>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </>
    );
};
