import { fetchConToken } from "../../helpers/fetch";
import { previewTypes } from "./previewTypes";


export const previewStartLoading = (idColaborator = null) => {
    return async (dispatch, getState) => {
        const {collaborator} = (idColaborator === null) ? getState().general : getState().evaluator;
        const idCollaborator = (idColaborator === null) ? collaborator[0].id : collaborator.id;
        try {
            const resp = await fetchConToken(`commitments/review/${idCollaborator}`);
            const body = await resp.json();
            dispatch(commitmentsLoaded(body.commitments));
        } catch (error) {
           console.error(error) ;
        }
    }
}
export const agreePrevStartLoading = (idCollaborator = null) => {
    return async (dispatch, getState) => {
        try {
            const resp = await fetchConToken(`commitments/review/${idCollaborator}`);
            const body = await resp.json();
            dispatch(commitmentsLoaded(body.commitments));
        } catch (error) {
           console.error(error) ;
        }
    }
}


const commitmentsLoaded = (commitments) => ({
    type: previewTypes.commitments,
    payload: commitments
})

export const clearCommitments = () => ({type: previewTypes.clearCommitments});
