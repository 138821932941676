import React from 'react'
import { NavBar } from '../../navbar/NavBar'

export const Loading = ({message = 'Cargando....'}) => {
    return (
        <div>
            <NavBar/>
            <h5>{message}</h5>
        </div>
    )
}
