import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Subtitle } from "../ui/subtitle/Subtitle";
import { AddButton } from "../buttons/add/AddButton";
import { Lists } from "../ui/lists/Lists";
import { gradeStartLoading, OpenMGrade } from "./gradeActions";
import { GradeModal } from "./GradeModal";

export const GradeScreen = () => {
  const dispatch = useDispatch();
  const {grades} = useSelector(state => state.grade);
  useEffect(() => {
      dispatch(gradeStartLoading());
  }, [dispatch]);
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value="Grados" />
        <Lists rows={grades} edit={true} idList='gradeList' openModal={OpenMGrade()}/>
      </div>
      <BackButton path="/main" />
      <AddButton open={OpenMGrade()} />
      <GradeModal/>
    </div>
  );
};
