import { previewTypes } from "./previewTypes";

const initial = {
    commitments: []
}

export const previewReducer = (state = initial, action) => {
    switch (action.type) {
        case previewTypes.commitments:
            return{
                ...state,
                commitments: action.payload
            }
        case previewTypes.clearCommitments:
            return{
                ...state,
                commitments: []
            }
        default:
            return state;
    }
}