import { functionalTypes } from "./functionalTypes"


const initialState = {
    functionals: [],
    modalOpenG: false,
    activeFunctional: null
}

export const functionalReducer = (state = initialState, action) => {
    switch (action.type) {
        case functionalTypes.functionalSetActive:
            return{
                ...state,
                activeFunctional: action.payload,
            }
        case functionalTypes.functionalAddNew:
            return{
                ...state,
                functionals:[
                    ...state.functionals,
                    action.payload
                ] 
            }
        case functionalTypes.functionalClearActiveFunctional:
            return{
                ...state,
                activeFunctional: null
            }
        case functionalTypes.functionalUpdated:
            return{
                ...state,
                functionals: state.functionals.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case functionalTypes.functionalDeleted:
            return{
                ...state,
                functionals: state.functionals.filter(
                    b => (b.id !== state.activeFunctional.id)
                ),
                activeFunctional: null
            }
        case functionalTypes.functionalLoaded:
            return{
                ...state,
                functionals: action.payload,
            }
        case functionalTypes.OpenMFunctional:
            return {
                ...state,
                modalOpenG: true
            }
        case functionalTypes.CloseMFunctional:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
