import React from "react";
import { useDispatch } from "react-redux";

export const VariosButton = ({ nameButton, idButton, varios, action, colorVarios, setVarios }) => {
  const dispatch = useDispatch();

  function handleUpdate(e) {
    e.preventDefault();
    if(setVarios != 'none'){
        action();
    }
    else {
        dispatch(action);  
    }
  }
  return (
    <button
      className={colorVarios}
      name={nameButton}
      id={idButton}
      onClick={handleUpdate}
      //onClick={modal === true ? handleUpdate : openModal}
      style={{ paddingBottom: "8px" }}
    >
      <i className={varios}></i>
    </button>
  );
};