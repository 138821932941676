import { teamTypes } from "./teamTypes"


const initialState = {
    teams: [],
    modalOpenG: false,
    activeTeam: null
}

export const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case teamTypes.teamSetActive:
            return{
                ...state,
                activeTeam: action.payload,
            }
        case teamTypes.teamAddNew:
            return{
                ...state,
                teams:[
                    ...state.teams,
                    action.payload
                ] 
            }
        case teamTypes.teamClearActiveTeam:
            return{
                ...state,
                activeTeam: null
            }
        case teamTypes.teamUpdated:
            return{
                ...state,
                teams: state.teams.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case teamTypes.teamDeleted:
            return{
                ...state,
                teams: state.teams.filter(
                    b => (b.id !== state.activeTeam.id)
                ),
                activeTeam: null
            }
        case teamTypes.teamLoaded:
            return{
                ...state,
                teams: action.payload,
            }
        case teamTypes.OpenMTeam:
            return {
                ...state,
                modalOpenG: true
            }
        case teamTypes.CloseMTeam:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
