import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { configuration } from "../../types/conf";
import { gradeTypes } from "./gradeTypes";
export const gradeStartAddNew = (grade) => {
    return async( dispatch, getState) => {
        //const { id } = getState().auth;
        try {
            const resp = await fetchConToken('grades/add', grade, 'POST');
            const body = await resp.json();
            if(body.grade === 'add'){
                grade.id = body.id;
                dispatch(gradeAddNew(grade));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const gradeAddNew = (grade) => ({
    type: gradeTypes.gradeAddNew,
    payload: grade
});

export const gradeSetActive = (grade) => ({
    type: gradeTypes.gradeSetActive,
    payload: grade
});

export const gradeClearActiveGrade = () => ({
    type: gradeTypes.gradeClearActiveGrade,
});

export const gradeStartUpdate = ( grade ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`grades/update/${ grade.id }`, grade, 'PUT' );
            const body = await resp.json();

            if ( body.grades !== 'null' ) {
                dispatch( gradeUpdated( grade ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const gradeUpdated = (grade) => ({
    type: gradeTypes.gradeUpdated,
    payload: grade
});

export const gradeDeleted = (grade) => ({ type: gradeTypes.gradeDeleted });

export const gradeStartLoading = () => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`grades/${ configuration.year }`);
                const body = await resp.json();
                const grades = body.grades;
                dispatch(gradeLoaded(grades));
            } catch (error) {
            console.error(error);
        }
    }
}

const gradeLoaded = (grades) => ({
    type: gradeTypes.gradeLoaded,
    payload: grades,
})

export const OpenMGrade = () => ({ type: gradeTypes.OpenMGrade });
export const CloseMGrade = () => ({ type: gradeTypes.CloseMGrade });
