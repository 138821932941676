import { fetchConToken } from "../../helpers/fetch";
import { trainingTypes } from "./trainingTypes";

export const startAddTraining = (training) => {
    return async (dispatch, getState) => {
        const { collaborator } = getState().general;
        training.collaborator = collaborator[0].id;
        try {
            const resp = await fetchConToken(`training/add`,training,'POST');
            const body = await resp.json();
            if(body.training !== 'null'){
                dispatch(trainingAdd(training));
            }
        } catch (error) {
            console.error(error);
        }
    }
}

const trainingAdd = (training) => ({
    type: trainingTypes.addTraining,
    payload: training
})

export const trainingclose = () => ({type: trainingTypes.closeTraining});