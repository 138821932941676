import Swal from "sweetalert2";
import { fetchConToken } from "../../helpers/fetch";
import { functionalTypes } from "./functionalTypes";
export const functionalStartAddNew = (functional) => {
    return async( dispatch) => {
        try {
            const resp = await fetchConToken('functionals/add', functional, 'POST');
            const body = await resp.json();
            if(body.functionals === 'add'){
                functional.id = body.id;
                dispatch(functionalAddNew(functional));
            }
        } catch (error) {
            console.error(error);
        }
        

        
    }
}

const functionalAddNew = (functional) => ({
    type: functionalTypes.functionalAddNew,
    payload: functional
});

export const functionalSetActive = (functional) => ({
    type: functionalTypes.functionalSetActive,
    payload: functional
});

export const functionalClearActiveFunctional = () => ({
    type: functionalTypes.functionalClearActiveFunctional,
});

export const functionalStartUpdate = ( functional ) => {
    return async(dispatch) => {

        try {
            const resp = await fetchConToken(`functionals/update/${ functional.id }`, functional, 'PUT' );
            const body = await resp.json();

            if ( body.functionals !== 'null' ) {
                dispatch( functionalUpdated( functional ) );
            } else {
                Swal.fire('Error', body.error, 'error');
            }


        } catch (error) {
            console.error(error)
        }

    }
}

const functionalUpdated = (functional) => ({
    type: functionalTypes.functionalUpdated,
    payload: functional
});

export const functionalDeleted = (functional) => ({ type: functionalTypes.functionalDeleted });

export const functionalStartLoading = (position) => {
    return async(dispatch) => {
        try {
                const resp = await fetchConToken(`functionals/${ position }`);
                const body = await resp.json();
                const functionals = body.functionals;
                dispatch(functionalLoaded(functionals));
            } catch (error) {
            console.error(error);
        }
    }
}

const functionalLoaded = (functionals) => ({
    type: functionalTypes.functionalLoaded,
    payload: functionals,
})

export const OpenMFunctional = () => ({ type: functionalTypes.OpenMFunctional });
export const CloseMFunctional = () => ({ type: functionalTypes.CloseMFunctional });
