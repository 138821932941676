import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { agreeStartLoading } from '../agreements/agreementsActions';
import { MainButton } from '../buttons/main/MainButton';
import { colaboratorStartLoading, collaboratorClear, evaluatorStartAddNew, getTeamEvaluator } from '../evaluators/evaluatorActions';
import { generalStartLoading } from '../general/generalActions';
import { Lists } from '../ui/lists/Lists';
import { Loading } from '../ui/loading/Loading';

export const DashboardEvaluator = ({router, handleClick}) => {
    const menuEvaluator = [
        {   name: "concertacion",   id : "agreement",    value: "Concertación" },
        {   name: "evaluacion",   id : "evaluation",    value: "Evaluación" }, 
    ];
    const dispatch = useDispatch();
    const {id} = useSelector(state => state.auth);
    useEffect(() => {
        dispatch(getTeamEvaluator());
    }, [dispatch]);
    return (
        <div>
            <div className="container">
                    <div className="row">
                    {
                        menuEvaluator.map(menu => {
                            return(
                                <div key={menu.id} className="col-md-4 col-xs-6 p-3">  
                                <MainButton
                                    key={menu.id}
                                    nameButton={menu.name}
                                    idButton={menu.id}
                                    valueButton={menu.value}
                                    handleClick={handleClick}
                                    router={router}
                                />
                            </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}
/*
export const DashboardEvaluator = ({ router, handleClick }) => {
    const {colaborators, collaborator, activeEvaluator} = useSelector(state => state.evaluator);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTeamEvaluator());
        if(activeEvaluator){
            dispatch(colaboratorStartLoading(activeEvaluator[0].id,true));
        }
    }, [dispatch])
    console.log({collaborator});
    if(collaborator !== null && activeEvaluator){
        console.log(collaborator.id);
        (collaborator.id !== null) ? router.push('/mainEvaluator') : <></>;
    }
    if(activeEvaluator === null){
        return <Loading/>;
    }
    return (
        <div>
            <div className="container">
                <Subtitle value="Evaluados a su cargo"/>
                Seleccione evaluado:
                <div>
                        <Lists rows={colaborators} idList='colabEvalList' varios={true}/>
                </div>
            </div>
        </div>
    )
}*/