import { userTypes } from "./userTypes";

const init = {
    modalOpen: false,
    activeUser: null,
    types: []
}

export const userReducer = (state = init, action) => {
    switch (action.type) {
        case userTypes.OpenMUser:
            return {
                ...state,
                modalOpen: true
            }
        case userTypes.CloseMUser:
            return {
                ...state,
                modalOpen: false
            }
        case userTypes.userSetActive:
            return {
                ...state,
                activeUser: action.payload
            }
        case userTypes.userClearActive:
            return {
                ...state,
                activeUser: null,
                types: []
            }
        case userTypes.userSetTypes:
            return{
                ...state,
                types: action.payload
            }
        default:
            return state;
    }
};
