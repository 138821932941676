import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { configuration } from '../../types/conf'
import { BackButton } from '../buttons/back/BackButton'
import { MainButton } from '../buttons/main/MainButton'
import { NavBar } from '../navbar/NavBar'
import { Subtitle } from '../ui/subtitle/Subtitle'
import { generalStartAddNew, generalSteps } from './generalActions'


export const GeneralScreen = () => {
    const router = useHistory();
    const dispatch = useDispatch();
    const { collaborator, data } = useSelector(state => state.general);
    const collab = collaborator[0];
    const initGeneral = {
        collaborator: collab.id,
        step: 1,
        state: 0,
        remote: collab.remote,
        customer: collab.customer,
    }
    const [formValues, setFormValues] = useState(initGeneral);
    const {remote, customer} = formValues;

    useEffect(() => {
            setFormValues(initGeneral);
    }, [setFormValues]);
    
    let updState = {
        step: 1,
        state: 0
    };
    /*
    const handleAgree = (e) => {
        e.preventDefault();
        console.log(e.target);
        updState.collaborator = collab.id;
        updState.state = 1;
        updState.remote = 1;
        updState.customer = 1;
      //  dispatch(generalSteps(updState));
        router.push('/main');
    }
    */
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleDissagree = (e) => {
        e.preventDefault();
        updState.collaborator = collab.id;
        updState.state = 0;
        router.push('/main');
    }
    const handleSubmitForm = (e) =>{
        e.preventDefault();
        updState.collaborator = collab.id;
        updState.state = 1;
        dispatch(generalStartAddNew(formValues));
        try {
            dispatch(generalSteps(updState));  
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Datos guardados con exito.',
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Error;'+error,
                showConfirmButton: false,
                timer: 1500
            }); 
        }
        
        router.push('/main');
    }
    return (
        <div>
            <NavBar />
            <form 
                className="container"
                onSubmit={handleSubmitForm}
            >
                <Subtitle value="Paso 1 verificaci&oacute;n de Datos Generales" />

                <div className='alert alert-info'>
                    <div className='row'>
                        <div className='col-4'>Nombre:</div>
                        <div className='col-8'>
                            <strong>{collab.collaborator}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>Cargo:</div>
                        <div className='col-8 '>
                            <strong>{collab.position}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>Nivel Jerarquico:</div>
                        <div className='col-8 '>
                            <strong>{collab.level}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>Dependencia:</div>
                        <div className='col-8 '>
                            <strong>{collab.area}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>Evaluador:</div>
                        <div className='col-8 '>
                            <strong>{collab.evaluator}</strong>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'><strong>¿Puede realizar trabajo remoto?</strong></div> 
                        <div className='col-8 '>
                            <strong>
                            <div className="form-group">
                                <select 
                                    className="form-select" 
                                    aria-label="Remote"
                                    name="remote"
                                    value={remote}
                                    onChange={handleInputChange}
                                    >
                                        <option value={1}>Si</option>
                                        <option value={0}>No</option>
                                </select>
                            </div>
                            </strong>
                        </div>
                        <br />
                        <br />
                    </div>
                    <div className='row'>
                        <div className='col-4'><strong>¿Realiza atención al ciudadano?</strong></div>
                        <div className='col-8 '>
                        <strong>
                            <div className="form-group">
                                <select 
                                    className="form-select" 
                                    aria-label="Remote"
                                    name="customer"
                                    value={customer}
                                    onChange={handleInputChange}
                                    >
                                        <option value={1}>Si</option>
                                        <option value={0}>No</option>
                                </select>
                            </div>
                            </strong>
                        </div>
                    </div>
                </div>
                <p>
                    Si estas de acuerdo con la información presentada, dar clic en Estoy de acuerdo.
                    En caso contrario por favor escribe un email a gestionhumana@rtvc.gov.co  y dar clic en Datos Errados
                </p>
                <div className='row'>
                    <div className='col'>
                        <MainButton
                            nameButton="sGeneral"
                            idButton="sGeneral"
                            handleClick={handleSubmitForm}
                            valueButton="  Estoy de acuerdo"
                            icon="far fa-thumbs-up"
                            back={configuration.greenColor}
                        />
                    </div>
                    <div className='col'>
                        <MainButton
                            nameButton="nGeneral"
                            idButton="nGeneral"
                            handleClick={handleDissagree}
                            valueButton=" Datos Errados"
                            icon="far fa-thumbs-down"
                            back="red"
                            text="white"
                        />
                    </div>
                </div>
            </form>
            <BackButton path="/main" />
        </div>
    )
}
