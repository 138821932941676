import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles.css';
import { configuration } from '../../types/conf';
import { MainButton } from '../buttons/main/MainButton';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { OpenMUser, setUserActive, userClearActiveUser } from '../users/userActions';
import { UserModal } from '../users/UsersModal';
import { CloseMTeam, teamClearActiveTeam, teamStartAddNew, teamStartUpdate } from './teamActions';

const customStyles={
    content: {
        top        : 'auto',
        left       : 'auto',
        right      : 'auto',
        bottom     : 'auto',
        marginRight: '0%',
        Transform  : 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initTeam = {
    name: '',
    year: configuration.year,
    active: 1,
    email: '',
    position: 1,
    area: 1,
    remote: 0, 
    customer: 0
}
export const TeamModal = () => {
    const { activeTeam, modalOpenG }  = useSelector(state => state.team);
    const [formValues, setFormValues] = useState(initTeam);
    const {positions} = useSelector(state => state.position);
    const {areas} = useSelector(state => state.area);
    const [titleValid, settitleValid] = useState(true);
    const dispatch = useDispatch();
    const {name, position, area, email, active, year, remote, customer } = formValues;
    useEffect(() => {
        if(activeTeam) {
            setFormValues(activeTeam);
            dispatch(setUserActive(activeTeam));
            OpenMUser();
        }else {
            setFormValues(initTeam);
        }
    }, [activeTeam, setFormValues]);
    const closeModalG = () => {
        dispatch( CloseMTeam());
        dispatch(userClearActiveUser());
        dispatch(teamClearActiveTeam());
        setFormValues(initTeam);
    }
    const handleInputChange = ({target}) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }
    const handleSubmitForm = (e) =>{
        e.preventDefault();
       
        if(name.trim().length < 1 ){
            settitleValid(false);
            return;
        }
        if( activeTeam) {
            dispatch(teamStartUpdate(formValues));
        } else {
            dispatch(teamStartAddNew(formValues));
        }
        settitleValid(true);
        closeModalG();
    }
    return (
        <Modal
            isOpen={modalOpenG}
            onRequestClose={closeModalG}
            style={customStyles}
            closeTimeoutMS={200}
            className='modal'
            overlayClassName='modal-fondo'
        >
            <Subtitle value={ (activeTeam) ? 'Editar Evaluado' : 'Crear Evaluado'} close={true} fclose={closeModalG}/>
            <form 
            className="container"
            onSubmit={handleSubmitForm}
            >
            <div className="form-group">
                <label>Nombre</label>
                <input 
                    type="text" 
                    className={`form-control ${!titleValid && 'is-invalid'}`}
                    placeholder="Nombre del evaluado"
                    name="name"
                    autoComplete="off"
                    value={name}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted">Nombre del Grado</small>
            </div>
            <div className="form-group">
                <label>Cargo</label>
                <select 
                    className="form-select" 
                    aria-label="Active"
                    name="position"
                    value={position}
                    onChange={handleInputChange}
                    >
                    <option key={0} value={0}>Seleccione...</option>
                    {
                        positions.map((position, index) => {
                            return(
                            <option key={index} value={position.id}>{position.name}</option>
                            );
                        })
                    }
                </select>
                <small id="titleHelp" className="form-text text-muted">Cargo del evaluado</small>
            </div>
            <div className="form-group">
                <label>Area</label>
                <select 
                    className="form-select" 
                    aria-label="Active"
                    name="area"
                    value={area}
                    onChange={handleInputChange}
                    >
                    <option key={0} value={0}>Seleccione...</option>
                    {
                        areas.map((area, index) => {
                            return(
                            <option key={index} value={area.id}>{area.name}</option>
                            );
                        })
                    }
                </select>
                <small id="titleHelp" className="form-text text-muted">Area del evaluado</small>
            </div>
            <div className="form-group">
                <label>Email</label>
                <input 
                    type="text" 
                    className={`form-control ${!titleValid && 'is-invalid'}`}
                    placeholder="@rtvc.gov.co"
                    name="email"
                    autoComplete="off"
                    value={email}
                    onChange={handleInputChange}
                />
                <small id="titleHelp" className="form-text text-muted">Email institucional</small>
            </div>
            <div className="form-group">
                <label>Activo</label>
                <select 
                    className="form-select" 
                    aria-label="Active"
                    name="active"
                    value={active}
                    onChange={handleInputChange}
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Esta activo?</small>
            </div>
            <div className="form-group">
                <label>¿Puede realizar trabajo remoto?</label>
                <select 
                    className="form-select" 
                    aria-label="Remote"
                    name="remote"
                    value={remote}
                    disabled
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
            </div>
            <div className="form-group">
                <label>¿Realiza atención al ciudadano?</label>
                <select 
                    className="form-select" 
                    aria-label="Customer"
                    name="customer"
                    value={customer}
                    disabled
                    >
                        <option value={1}>Si</option>
                        <option value={0}>No</option>
                </select>
            </div>
            <div className="form-group">
                <label>Año</label>
                <select 
                    className="form-select" 
                    aria-label="Year"
                    name="year"
                    value={year}
                    onChange={handleInputChange}
                    >
                        <option value={configuration.year}>{configuration.year}</option>
                </select>
                <small id="logoHelp" className="form-text text-muted">Vigencia</small>
            </div>
            <br />
            <MainButton
                nameButton="sTeam"
                idButton="sTeam"
                valueButton=" Guardar"
                handleClick={handleSubmitForm}
                icon="far fa-save"
            />
            </form>
            <br/>
            <div className="container">
                <UserModal/>
            </div>
            
        </Modal>
    )
}