import { positionTypes } from "./positionTypes"


const initialState = {
    positions: [],
    modalOpenG: false,
    activePosition: null
}

export const positionReducer = (state = initialState, action) => {
    switch (action.type) {
        case positionTypes.positionSetActive:
            return{
                ...state,
                activePosition: action.payload,
            }
        case positionTypes.positionAddNew:
            return{
                ...state,
                positions:[
                    ...state.positions,
                    action.payload
                ] 
            }
        case positionTypes.positionClearActivePosition:
            return{
                ...state,
                activePosition: null
            }
        case positionTypes.positionUpdated:
            return{
                ...state,
                positions: state.positions.map(
                    b => (b.id === action.payload.id) ? action.payload : b
                )
            }
        case positionTypes.positionDeleted:
            return{
                ...state,
                positions: state.positions.filter(
                    b => (b.id !== state.activePosition.id)
                ),
                activePosition: null
            }
        case positionTypes.positionLoaded:
            return{
                ...state,
                positions: action.payload,
            }
        case positionTypes.OpenMPosition:
            return {
                ...state,
                modalOpenG: true
            }
        case positionTypes.CloseMPosition:
            return {
                ...state,
                 modalOpenG: false
             }
        default:
            return state;
    }
}
