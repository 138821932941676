import React, { useEffect } from 'react'
import { NavBar } from '../navbar/NavBar';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { BackButton } from '../buttons/back/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { previewStartLoading } from './previewActions';
import { Loading } from '../ui/loading/Loading';
import { Review } from '../review/Review';
import { MainButton } from '../buttons/main/MainButton';
import { configuration } from '../../types/conf';
import { generalSteps } from '../general/generalActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const PreviewScreen = () => {
  const {commitments} = useSelector(state => state.preview);
  const { collaborator } = useSelector(state => state.general);
  const collab = collaborator[0];
  const router = useHistory();
  let percent = 0;
  let updState = {
    step: 2,
    state: 1,
    collaborator: collab.id
  };
  const handleAgree = (e) =>{
    e.preventDefault();
    console.log('entre clic');
    dispatch(generalSteps(updState));
    router.push('/main');
  }
  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(previewStartLoading());
    }, [dispatch]);
  if(commitments.length=== 0 || commitments=== 'null' || commitments === []){
    return <Loading message='Por favor verifique que creó el total de actividades en cada meta. (Clic en inicio barra superior).'/>
  }
  else{
    var idGoal = 0;
    var printGoal = true;
    commitments.map((c, index) => {
      printGoal = (idGoal === c.idGoal) ? false : true;
      idGoal = c.idGoal;
      percent += (printGoal) ? parseInt(c.percent) : 0;
    });
  return (
    <div>
      <NavBar />
      <div className='container'>
        <Subtitle value={'Compromisos a Concertar:'} />
        <div style={{height:'72vh', overflowY: 'scroll', overflowX: 'hidden'}} className="overflow-scroll">
        {
          (commitments !== [])
            ?<Review commitments={commitments}/>
            : <></>
        }
         
        </div>
        {
          (percent === 100)
          ? <MainButton
            nameButton='step2end'
            idButton='step2end'
            handleClick={handleAgree}
            valueButton='  Estoy de acuerdo'
            icon="far fa-thumbs-up"
            back={configuration.greenColor}
          />
          : <div className="alert alert-danger d-flex align-items-center" role="alert">
              <i className="fas fa-exclamation-circle"></i>
              <div>
                 <b> Algo esta mal!</b> Por favor revisa que creaste todos tus objetivos, compromisos y actividades y que estas suman el 100%.
              </div>
            </div>
        }
        
      </div>
      <BackButton path="/commitments" />
    </div>
  )
}
}
