import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { configuration } from '../../types/conf';
import { BackButton } from "../buttons/back/BackButton";
import { NavBar } from "../navbar/NavBar";
import { Lists } from '../ui/lists/Lists';
import { Loading } from '../ui/loading/Loading';
import { Subtitle } from "../ui/subtitle/Subtitle";
import { clearCheckColaborators, colaboratorStartLoading, evaluatorStartAddNew } from './evaluatorActions';


export const AddEvalScreen = () => {
    const {colaborators, activeEvaluator, collaborator} = useSelector(state => state.evaluator);
    const dispatch = useDispatch();
    const editColaborator = (e) => {
        const collaborator = e.target.name;
        e.preventDefault();
    }
    if(collaborator){
        const evaluator = {
            colaborator: collaborator.id,
            evaluator: (collaborator.evaluator !== '0') ? 0 : activeEvaluator.id,
            active: 1,
            year: configuration.year,
            name: collaborator.name
        }
        dispatch(evaluatorStartAddNew(evaluator));
    }
    useEffect(() => {
        dispatch(clearCheckColaborators());
    }, [dispatch]);
    if(colaborators.length === 0){
        return <Loading/>;
    }
    return (
        <div>
            <NavBar />
            <div className="container">
                <Subtitle value={activeEvaluator.name + " va evaluar a:"} />

                <div className="container">
                    Seleccione los evaluados:
                    <div>
                        <Lists rows={colaborators} idList='colaboratorList' varios={true}/>
                    </div>
                </div>
            </div>
            <BackButton path="/evaluators" />
        </div>
    )
}
